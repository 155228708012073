module.exports = {
    convert_to_text_title:"Collaborate with teams",
    convert_to_text_desc:"Cloud-based team account with shared transcript and labels",
    start_your_trial:"Start your free trial!",
    hero_img:"./06_English.webp",

    step_one:"Multi-user access",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"Add users to your account and manage",
    
    step_two:"Share and edit transcript",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"Add users to your account to share transcripts and minutes",

    step_three:"User permissions management",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"Manage accounts viewing and edit permissions",

    feature_tutorial_title:'User management tutorial: Elevate team collaboration with multi-user access',
    feature_tutorial_custom_desc:'In addition to the 6 pre-set permissions, you can also customize access to each feature for every user under the account.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'Are you interested in smoothing out your workflow while - at the same time - making your billing more straightforward? Then Taption\'s multi-user access might be the solution you\'ve been looking for. With our cloud-based platform at the heart of it all, you can expect more costefficient operations alongside excellent user management.<br><br>Does this sound promising? Don\'t forget to check out our detailed tutorial to get all the insights.',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"Why managing user permissions makes sense strategically",
    feature_extra_desc:"Managing who gets to do what in Taption isn't just an annoying admin chore. It's a smart choice that can reshape your team's interaction with our platform. If you are intrigued now, then here are some solid reasons why you should take action: <ul><li><strong>Seamless Integration:</strong> Adding new members to the team is really easy and it helps everyone adjust without trouble. </li><li><strong>Secure Your Data:</strong> Role-based access guarantees that sensitive data stays protected, so that key transcripts remain intact and in the right hands. </li><li><strong>Focused Expertise:</strong> Limiting certain platform features to particular team members helps build specialization and raises the bar for your projects. </li><li><strong>Keep Things in Check:</strong> With full management control, you can oversee shared transcript use and feature activation, which guarantees a safe and harmonious operational space.</li></ul>By adopting these user management tactics, you're bringing out the best in both your team and Taption. How about taking that step today?"
}