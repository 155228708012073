module.exports = {
    convert_to_text_title_meta: "オンラインAI音声テキスト変換：ビデオ、録音、Youtubeを文字起こしに自動変換",
    convert_to_text_desc_meta: "オンラインでビデオ、録音、Youtubeの音声をテキストに自動変換します。40以上の言語をサポートし、高精度を提供。機能には文字起こし出力、会議記録の登壇者分割、字幕用のテキスト分割が含まれます。無料体験可能、今すぐ登録して使用開始！",
   convert_to_text_title:"動画をテキストに変換しましょう",
    //convert_to_text_desc:"MP4動画を自動的にMP3音声ファイルやテキストに変換できます。",
    convert_to_text_desc:'MP4ビデオやMP3録音を自動的に時間コードが埋め込まれたテキストに変換し、40以上の言語をサポートし、自動的にセグメント化され、高い精度を持ちます',
    start_your_trial:"今すぐ無料トライアルを試す",
    hero_img:"./01_English.webp",

    three_step_title:"文字起こし方法",
    step_one:"ファイルをアップロードします",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"デバイスやYoutube、Googleドライブ、Zoomより選んだファイルをテキストに変換します。",
    
    step_two:"文字起こし方法を選んでください",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"次の方法より文字起こし方法をお選びください: 1) 自動文字起こし 2) テキストファイルをインポート 3) 手動入力。それから \"記事\"の各セクションの「区分け」を選んでください。",

    step_three:"エクスポート",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"プラットフォームで編集を完了したら、右上のエクスポートをクリックしてファイル(srt,txt,pdf,mp4)を抽出できます。",

    feature_tutorial_title:'動画をテキストに変換する方法',
    feature_tutorial_desc:'無論MP4をテキストに変換するか、MP3をテキストに変換するか、アップロード後に当社の編集プラットフォームを使用してテキスト、段落、および時間を調整し、エクスポートできます！<br>Taptionは、次の4つの字幕追加方法を提供します：<span class="bullet-point">•</span>AIによる自動字幕追加 <span class="bullet-point">•</span>既存のSRTファイルのインポート <span class="bullet-point">•</span>TXT純テキストファイルのインポート <span class="bullet-point">•</span>手動入力。<br>テキストへの変換手順以外にも、当社の強力な編集プラットフォームが校正にかかる時間を短縮するのに役立ちます。Taptionは50種類以上の言語の二重字幕を追加できます！動画をご覧ください：',
    //feature_tutorial_desc:'MP4またはMP3ファイルをテキストに変換したら、私たちが提供する編集プラットフォームの豊富な機能を使って編集できます。翻訳をする場合は、(2か国語)字幕を追加するか話し手ラベリングを追加してください。詳しくはリンクをクリックしてください。動画をテキストに変換する方法に関しては、下のチュートリアル動画もぜひご覧ください!',
    // feature_tutorial_poster_url:'./convert-to-text-tutorial-poster-en.webp',
    // feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/ZGfYQDaZOX8?autoplay=1',

    feature_extra_title:"動画をテキストに変換することのメリットは？",
    feature_extra_desc:"動画をテキストに変換すると、字幕に活用できるのはもちろん、色んなところに活用できます。例えば、<ul><li> 動画ファイルが検索できるようになります</li><li> 内容のレビューや修正の効率が上がります</li><li>翻訳が簡単にできます</li><li>動画が検索できるようになると、サイトのSEOも改善します</li><li>聴覚障害者もコンテンツにアクセスでき、ADA（障害をもつアメリカ人法）などのコンプライアンスにもつながります</li></ul>",
    
    feature_why_image: "./translate_step1_en.webp",
    feature_what_title: "音声を文字に変換する5つのステップ",
    feature_what_desc: "<ul>\n  <li>新しいアカウントを登録するか、Gmailでログイン：<a href=\"https://app.taption.com/login\">Taptionログインページ</a>にアクセス。</li>\n  <li>「アップロード」ボタンをクリックし、ソースを選択（パソコンからのアップロード、YouTube、Google Drive、Zoom、または直接リンクを貼り付け）。</li>\n  <li>転写に必要な「言語」と「分割方法」を選択。</li>\n  <li>アップロード完了後、ファイルは自動的に文字に変換され、編集プラットフォームに移動して編集可能。</li>\n  <li>編集プラットフォームで翻訳、AI分析を行い、最終的にファイルや字幕をエクスポートして動画ファイルに埋め込むことができます。</li>\n</ul>"

}