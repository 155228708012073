module.exports = {
    title_0: 'How to transcribe my video?',
    desc_0:'See tutorial video',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",
    
    title_1: 'How to add subtitles to my video?',
    desc_1:'See tutorial video',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: 'How to translate my video?',
    desc_2:'See tutorial video',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: 'How to increase accuracy by using the AI Dictionary?',
    desc_3:'See tutorial video',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: 'How to edit my video by editing text?',
    desc_4:'See tutorial video',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: 'Where can I view my receipts and invoices?',
    desc_5:'Once subscribed, you will be able to view and download them under <span style="color:rgb(0, 209, 178);">Account → Invoices → View All</span>',
    video_5:"",

    title_6: 'I have subscribed to a subscription plan, what can I do if I need more than the monthly minutes given?',
    desc_6:'When subscribed to <span style="color:rgb(0, 209, 178);">Premium Subscription</span>, you can purchase additional minutes at a discounted price of <span style="color:rgb(0, 209, 178);">$6/hour</span>. When subscribed to <span style="color:rgb(0, 209, 178);">Bulk Subscription</span>, you can purchase additional minutes at a discounted price of <span style="color:rgb(0, 209, 178);">$3/hour</span>',
    video_6:"",

    title_7: 'Transcript is not translating, how can I resolve it?',
    desc_7:'Please ensure that you selected the correct language when uploading the video. You should select the language matching the spoken language in the video.',
    video_7:"",

    title_8: 'I uploaded a file less than 5GB, why do I get the message <span style="color:rgb(230, 118, 117);">Video size too large</span>?',
    desc_8:'The size limit of 5GB is based on MP4 format. When uploading a video with a format other than MP4, the converted MP4 may result in a size larger than 5GB. Please upload an MP4 format video less than 5GB to avoid this message.',
    video_8:"",

    title_9: 'What do the error means and what can I do to resolve it?',
    desc_9:'<span style="color:rgb(230, 118, 117);">Exceed trial usage</span> : Please purchase mintues or subscribe to continue using the service.<br><span style="color:rgb(230, 118, 117);">Not enough minutes</span> : Purchase more minutes or subscribe then retry.<br><span style="color:rgb(230, 118, 117);">Youtube link failed, please try again</span> : The most common reason is the provided Youtube was uploaded recently, please wait at least 24 hours before trying to transcribe again.',
    video_9:"",

    title_10: 'How to integrate Taption with Zoom?',
    desc_10:'Please see <a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">step-by-step tutorial</a>',
    video_10:"",

    title_11: 'I have further questions, what should I do?',
    desc_11:'Please contact us at <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    zoom_title:'Step-by-step Taption + Zoom integration',
    zoom_install_desc:'You can now connect your Zoom account with your Taption account. After this tutorial, you will be able to transcribe Zoom recordings directly from Taption.',
    zoom_step_1:'Click "Upload" after sigining in to your account',
    zoom_step_2:'Choose "Zoom" in the popup dialog',
    zoom_step_3:'Log in with you Zoom account and authorize',
    zoom_step_4:'Choose your recording from the table',
    zoom_step_5:'Configure your settings then you are ready to upload and transcribe',
    zoom_uninstall_title:'Uninstall Taption + Zoom integration',
    zoom_uninstall_desc:'To uninstall Zoom from Taption, you can do so by going under <strong>Account</strong> → <strong>Apps</strong> → <strong>Uninstall</strong><br>If you have further questions, please email us at <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'Step-by-step Taption + Webex integration',
    webex_install_desc:'You can now connect your Webex account with your Taption account. After this tutorial, you will be able to transcribe Webex recordings directly from Taption.',
    webex_step_1:'Click "Upload" after sigining in to your account',
    webex_step_2:'Choose "Webex" in the popup dialog',
    webex_step_3:'Log in with you Webex account and authorize',
    webex_step_4:'Choose your recording from the table',
    webex_step_5:'Configure your settings then you are ready to upload and transcribe',
    webex_uninstall_title:'Uninstall Taption + Webex integration',
    webex_uninstall_desc:'To uninstall Webex from Taption, you can do so by going under <strong>Account</strong> → <strong>Apps</strong> → <strong>Uninstall</strong><br>If you have further questions, please email us at <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
}