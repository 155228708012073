module.exports = {
    convert_to_text_title:"影音人物标记",
    convert_to_text_desc:"自动为影音标记人物",
    start_your_trial:"马上试！",
    hero_img:"./04_Chinese.webp",

    step_one:"上传影片或录音档",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"从电脑，Youtube, Google Drive或Zoom选取要标记人物的影音档案。",
    
    step_two:"选择「发声者」来分段",
    step_two_img:"./speaker_step2_zh.webp",
    step_two_desc:"上传后分段方式选择「发声者」后文字会自动根据说话人物分段并标记。 ",

    step_three:"完成",
    step_three_img:"./speaker_step3_zh.webp",
    step_three_desc:"转成文字后后进入编辑平台进行调整后即可导出！",

    feature_tutorial_title:'自动人物标记教学',
    feature_tutorial_desc:'影片转成文字后并自动标记人物可以让影片内容及结构更明确。参考以下教学影片！ ',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/tr8qrM8mnNA?autoplay=1',
    //https://youtu.be/tr8qrM8mnNA

    feature_extra_title:"会议记录的几个原则",
    feature_extra_desc:"<ul><li>记录重点在于掌握主题、点出整个会议的「议题」，记录的重点并非谈话内容，而是「与什么有关的谈话」。不过记录的内容还是要以实录性为重，切勿加入记录者的观点或倾向或删改发言者的言论内容</li><li>设法透过会议记录来「衔接会议」：会议记录也能是带领会议走向的指标，更能作为后续的提醒及依据如果能透过「先前的会议流程」与「日后的会议计画」来让与会者有所共识，就比较能避免失焦的混乱会议</li><li>当会议记录能一键把录音档全数转成文字的时候，以上就是撰写一份精简的会议报告该遵循的写作原则</li></ul>"
}