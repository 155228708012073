module.exports = {
  'zh-TW': '中文 (輸出繁體)',
  'zh': '中文 (輸出簡體)',
  //'zh-CHT': '中文 + 台語 (輸出繁體)',
  'yue-Hant-HK-TW-zh': '粵語  (輸出中文繁體)',
  'yue-Hant-HK-zh': '粵語  (輸出中文簡體)',
  'yue-Hant-HK-TW': '粵語 (輸出粵語繁體)',
  'yue-Hant-HK': '粵語 (輸出粵語簡體)',
  'en-US': '英語 (美國)',
  'en-GB': '英語 (英國)',
  'en-AU': '英語 (澳大利亞)',
  'es-ES': '西班牙文 (西班牙)',
  'fr-FR': '法語 (法國)',
  'de-DE': '德文 (德國)',
  'it-IT': '意大利語 (意大利)',
  'pt-PT_pt-BR': '葡萄牙語 (葡萄牙，巴西)',
  'nl-BE': '荷蘭語 (比利時)',
  'hi-IN': '印地語 (印度)',
  'ja-JP': '日語 (日本)',
  'ko-KR': '韓文 (韓國)',
  'vi-VN': '越語 (越南)',

  'ar-EG': '阿拉伯語 (埃及)',
  'hy-AM': '亞美尼亞語 (亞美尼亞)',
  'bs-BA': '波斯尼亞語 (波黑)',
  'bg-BG': '保加利亞語 (保加利亞)',
  'my-MM': '緬甸語 (緬甸)',
  'ca-ES': '加泰羅尼亞語 (西班牙)',
  'hr-HR': '克羅埃西亞 (克羅地亞)',
  'cs-CZ': '捷克文 (捷克共和國)',
  'da-DK': '丹麥文 (丹麥)',
  //'en-IN': '英語 (印度)',
  //'fil-PH': '菲律賓語 (菲律賓)',
  'tl-PH': '菲律賓語 (菲律賓)',
  'fi-FI': '芬蘭文 (芬蘭)',
  'gl-ES': '加利西亞語 (西班牙)',
  'el-GR': '希臘文 (希臘)',
  'gu-IN': '古吉拉特語 (印度)',
  'iw-IL': '希伯來語 (以色列)',
  'hu-HU': '匈牙利文 (匈牙利)',
  'id-ID': '印尼文 (印尼)',
  'lv-LV': '拉脫維亞 (拉脫維亞)',
  'lt-LT': '立陶宛語 (立陶宛)',
  'mk-MK': '馬其頓語 (北馬其頓)',
  'ms-MY': '馬來西亞 (馬來西亞)',
  'no-NO': '挪威語 (挪威)',
  'pl-PL': '波蘭文 (波蘭)',
  'ro-RO': '羅馬尼亞語 (羅馬尼亞)',
  'ru-RU': '俄語 (俄羅斯)',
  'sk-SK': '斯洛伐克 (斯洛伐克)',
  'sl-SI': '斯洛文尼亞語 (斯洛文尼亞)',
  'sv-SE': '瑞典語 (瑞典)',
  'ta-IN': '泰米爾語 (印度)',
  'te-IN' : '泰盧固語 (印度)',
  'th-TH': '泰語 (泰國)',
  'tr-TR': '土耳其語 (土耳其)',
  'uk-UA': '烏克蘭語 (烏克蘭語)',
  'uz-UZ': '烏茲別克 (烏茲別克斯坦)',
  // 'zu-ZA': '祖魯 (南非)'
}
