module.exports = {
    convert_to_text_title:"음성을 동영상으로 변환하세요",
    convert_to_text_desc:"음성 파일과 이미지 파일을 고르면 자막이 추가된 MP4 파일로 변환됩니다",
    start_your_trial:"무료 시험판을 지금 시작하세요!",
    hero_img:"./05_English.webp",

    step_one:"음성을 텍스트로 변환하세요",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"음성파일을 업로드한 후 \"자막\"에서 각 구간별로 나누기를 선택하세요",
    
    step_two:"원하는 이미지를 업로드하세요",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"편집 플랫폼의 내보내기 → MP4 동영상 파일 메뉴에서 이미지를 업로드할 수 있습니다",

    step_three:"동영상을 내보내기",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"나만의 비디오를 파일로 내보내세요!",

    feature_tutorial_title:'음성을 동영상으로 변환하는 방법',
    feature_tutorial_desc:'음성 파일을 몇 분만에 동영상으로 변환하고 더 많은 시청자를 모으세요. 아래 튜토리얼을 지금 확인하세요!',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"음성 파일을 자막이 달린 동영상 파일로 변환하면 어떤 점이 좋을까요?",
    feature_extra_desc:"몇 분만에 음성 파일을 자막 달린 영상 파일로 변환할 수 있습니다. 음성 파일을 동영상으로 바꾸면 다음과 같은 효과가 있습니다.<ul><li>서로 다른 소셜 미디어 플랫폼에 맞춰서 같은 컨텐츠라도 다른 방식으로 사용할 수 있습니다</li><li>시청자와 독자적으로 의사소통할 수 있는 방법을 마련해줍니다</li><li>음성 및 영상 컨텐츠에 자막을 추가해서 전세계 시청자에게 접근할 수 있습니다</li></ul>"
}