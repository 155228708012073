module.exports = {
    convert_to_text_title:"录音档转影片",
    convert_to_text_desc:"自动将MP3录音档加上高清客制化图片转为有字幕的MP4影片",
    start_your_trial:"马上试！",
    hero_img:"./05_Chinese.webp",

    step_one:"将录音档转为文字",
    step_one_img:"./customized_step1_zh.webp",
    step_one_desc:"上传录音档并且以「字幕」形式分段转成文字",
    
    step_two:"上传一张高清图片",
    step_two_img:"./customized_step2_zh.webp",
    step_two_desc:"校正文字后导出选取MP4影片上传一张作为影片的背景图片",

    step_three:"导出",
    step_three_img:"./customized_step3_zh.webp",
    step_three_desc:"设定字体及大小后即可导出一部客制化影片",

    feature_tutorial_title:'录音档转客制化影片教学',
    feature_tutorial_desc:'录音档转影片可以在数分钟内完成，不仅有听众也有了观众。快来观看录音档转影片的教学影片：',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/2S_5lKr8Oyk?autoplay=1',

    feature_extra_title:"录音档转影片及输出成文字档的便利性",
    feature_extra_desc:"<ul><li>Podcast内容的影片化，对Podcaster而言，能进一步把作品影片化，以精美的图片作为影片背景，将Podcast内容转成YT影片，一次圈粉听众和观众</li><li>对Podcast听众来说，可以利用录音档输出成文字的功能，辅以一些便利的笔记APP来整理资讯，对于常听那些知识型Podcast的听众来说非常方便</li><li>迅速产生逐字稿，便于修正内容和制作纲要，也方便把影片做成文章</li><li>便于纪录并转换，作家日常口语记录的灵感、线上或实体课程内容的录音记录、会议记录、Podcast内容……等，录下来后直接一键转成文字，省时省力</li></ul>"
}