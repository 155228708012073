module.exports = {
    title_0: '如何把影片轉成文字?',
    desc_0:'請參考教學影片',
    video_0:"https://www.youtube.com/embed/4eWCXvkSiew",

    title_1: '如何為影片加字幕?',
    desc_1:'請參考教學影片',
    video_1:"https://www.youtube.com/embed/509iVOUAxcY",
    
    title_2: '如何翻譯影片?',
    desc_2:'請參考教學影片',
    video_2:"https://www.youtube.com/embed/q3YjCHoU8hU",
    
    title_3: '如何使用『AI辭典』和『全部替換』來增加準確性?',
    desc_3:'請參考教學影片',
    video_3:"https://www.youtube.com/embed/wRx_OfdDMJY",

    title_4: '如何使用『字幕剪輯影片片段』功能?',
    desc_4:'請參考教學影片',
    video_4:"https://www.youtube.com/embed/W_p0MlPw8hk",
    
    title_5: '如何下載發票和收據?',
    desc_5:'訂閱後，可隨時到 <span style="color:rgb(0, 209, 178);">我的帳號 → 訂閱發票 → 查看</span> 下載',
    video_5:"",
    
    title_6: '訂閱後，每個月贈送的分鐘數之外我還需要更多的分鐘數，可以怎麼辦?',
    desc_6:'如訂閱<span style="color:rgb(0, 209, 178);">高級訂閱</span>，可以優惠價格<span style="color:rgb(0, 209, 178);">6美金/小時</span>加購分鐘數。如訂閱<span style="color:rgb(0, 209, 178);">大量訂閱</span>，可以優惠價格<span style="color:rgb(0, 209, 178);">3美金/小時</span>加購分鐘數。',
    video_6:"",

    title_7: '翻譯後還是原來的語言，要怎麼解決?',
    desc_7:'請確認一開始上傳選擇的語言跟影片裡的發聲語言一樣。 (例: 上傳英文影片時，語言請選擇『英文』。完成後再進入編輯平台右上方進行翻譯)',
    video_7:"",

    title_8: '上傳的檔案小於5GB，為什麼得到 <span style="color:rgb(230, 118, 117);">影片過長</span> 的訊息?',
    desc_8:'5GB是以MP4的檔案為準，如果上傳其它類型(例:MOV)，伺服器會先轉檔成MP4。如果轉完的檔案大於5GB則會出現此訊息。可以自行轉成MP4再上傳來避免此訊息。',
    video_8:"",
    
    title_9: '這些錯誤訊息要如何解決?',
    desc_9:'<span style="color:rgb(230, 118, 117);">試用超額</span> : 請訂閱或購買更多分鐘數<br><span style="color:rgb(230, 118, 117);">您的分鐘數不足</span> : 請訂閱或購買更多分鐘數<br><span style="color:rgb(230, 118, 117);">此Youtube連結失敗，請再試一次</span> : 通常會發生在Youtube新上傳的影片上，請等待至少24小時後再重新上傳.',
    video_9:"",

    title_10: '如何將Taption與Zoom做串聯?',
    desc_10:'請參考<a href="https://www.taption.com/blog/zh/zoom-tutorial-zh" rel="noreferrer" target="_blank">教學文章</a>',
    video_10:"",
    
    
    title_11: '我有更多的問題，要怎麼聯絡你們?',
    desc_11: '請寄信到我們的信箱: <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    
    zoom_title:'如何將Taption與Zoom做串聯?',
    zoom_install_desc:'您可以將您的Zoom帳號與Taption做串聯，讓您可以直接從Taption介面選取需要轉成文字的Zoom會議。',
    zoom_step_1:'登入後點選「上傳」',
    zoom_step_2:'選擇Zoom作為影片來源',
    zoom_step_3:'進入Zoom頁面後登入帳號並認證',
    zoom_step_4:'選擇會議影片',
    zoom_step_5:'設定完後就可以上傳轉成文字了',
    zoom_uninstall_title:'如何取消Taption和Zoom的串聯?',
    zoom_uninstall_desc:'要取消串聯或是更換Zoom帳號可以到 <strong>我的帳號</strong> → <strong>已連接軟體</strong> → <strong>取消串聯</strong><br>如有更多問題歡迎寄信到 <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'如何將Taption與Webex做串聯?',
    webex_install_desc:'您可以將您的Webex帳號與Taption做串聯，讓您可以直接從Taption介面選取需要轉成文字的Webex會議。',
    webex_step_1:'登入後點選「上傳」',
    webex_step_2:'選擇Webex作為影片來源',
    webex_step_3:'進入Webex頁面後登入帳號並認證',
    webex_step_4:'選擇會議影片',
    webex_step_5:'設定完後就可以上傳轉成文字了',
    webex_uninstall_title:'如何取消Taption和Webex的串聯?',
    webex_uninstall_desc:'要取消串聯或是更換Webex帳號可以到 <strong>我的帳號</strong> → <strong>已連接軟體</strong> → <strong>取消串聯</strong><br>如有更多問題歡迎寄信到 <span style="color:rgb(0, 209, 178);">help@taption.com</span>'
}