module.exports = {
    convert_to_text_title_meta: "온라인 AI 음성 변환: 비디오, 녹음, Youtube를 문서로 자동 변환",
    convert_to_text_desc_meta: "온라인으로 비디오, 녹음, Youtube 음성을 텍스트로 자동 변환하며, 40개 이상의 언어를 지원하여 높은 정확도를 제공합니다. 기능은 문서 출력, 회의 기록에서 인물 분할, 자막 처리를 용이하게 하는 분할 등을 포함합니다. 무료 체험 가능, 지금 등록하여 사용해 보세요!",
    convert_to_text_title:"동영상을 텍스트로 변환",
    convert_to_text_desc:"MP4 비디오 및 MP3 녹음을 자동으로 시간 코드가 포함된 텍스트로 변환하며, 40여 개 이상의 언어를 지원하며 자동으로 세분화되며 고정확도를 지원합니다.",
    start_your_trial:"지금 무료 시험판을 시작하세요!",
    hero_img:"./01_English.webp",

    three_step_title:"받아쓰기 방법",
    step_one:"파일을 업로드",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"사용중인 장치나 YouTube, 구글 드라이브, 줌 등에서 파일을 선택하여 텍스트로 변환합니다.",
    
    step_two:"받아쓰기 방법 선택",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"다음 중에서 받아쓰기할 방법을 선택하세요: 1) 자동 받아쓰기 2) 텍스트 파일 불러오기 3) 수동 입력. 선택 후, \"글\" 메뉴에서 각 구간으로 나누기를 선택하세요.",

    step_three:"내보내기",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"플랫폼에서 편집을 완료한 후, 우측 상단의 내보내기 버튼을 눌러 파일(srt,txt,pdf,mp4)로 내보낼 수 있습니다.",

    feature_tutorial_title:'동영상을 텍스트로 변환하려면 어떻게 하나요?',
    feature_tutorial_desc:'무언가 MP4를 텍스트로 또는 MP3를 텍스트로 변환하든, 업로드 후에는 텍스트, 단락 및 시간을 조정하고 내보낼 수 있는 편집 플랫폼을 사용할 수 있습니다! <br> Taption은 다음과 같은 네 가지 자막 올리기 방법을 제공합니다：<span class="bullet-point">•</span>AI 자동 자막 올리기<span class="bullet-point">•</span>기존 SRT 파일 가져오기<span class="bullet-point">•</span>TXT 텍스트 파일 가져오기<span class="bullet-point">•</span>수동 입력. <br> 텍스트로 변환하는 단계 외에도, 강력한 편집 플랫폼이 교정에 필요한 시간을 줄이는 데 도움을 줄 것입니다. Taption은 또한 50가지 이상의 언어로 이중 자막을 제공할 수 있습니다! 우리의 교육 영상을 확인해 보세요：',
    //feature_tutorial_desc:'MP4 동영상 파일을 텍스트로 변환한 후, 탭션 편집 플랫폼의 풍부한 기능을 사용하여 편집할 수 있습니다. 번역을 하시려면, (2개국어) 자막 추가하기 혹은 발화자 라벨 붙이기를 선택하세요. 자세히 알아보시려면 링크를 클릭하세요. 동영상을 텍스트로 변환하는 방법에 관한 아래 튜토리얼도 꼭 확인하세요!',
    // feature_tutorial_poster_url:'./convert-to-text-tutorial-poster-en.webp',
    // feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/ZGfYQDaZOX8?autoplay=1',
    

    feature_extra_title:"동영상을 텍스트로 변환하면 어떤 점이 도움될까요?",
    feature_extra_desc:"동영상을 텍스트로 변환하면 자막으로 활용할 수 있을뿐 아니라, 다음과 같은 많은 이점이 있습니다.<ul><li> 영상 파일이 검색 가능해지며 검색 결과에 노출됩니다</li><li> 컨텐츠 리뷰 및 수정의 효율이 높아집니다</li><li>번역이 쉽게 가능해집니다</li><li>컨텐츠가 검색 가능해지면서 사이트의 SEO도 향상됩니다</li><li>컨텐츠가 청각 장애인도 접근할 수 있게 되어 미국 장애인법 등의 준수에도 연결됩니다</li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title: "음성을 텍스트로 변환하는 5단계",
    feature_what_desc: "<ul>\n  <li>새 계정을 등록하거나 Gmail로 로그인: <a href=\"https://app.taption.com/login\">Taption 로그인 페이지</a>로 이동합니다.</li>\n  <li>“업로드” 버튼을 클릭하고, 컴퓨터에서 업로드, YouTube, Google Drive, Zoom 또는 동영상 파일 링크를 붙여넣는 등 동영상 소스를 선택합니다.</li>\n  <li>필요한 “언어” 및 “분할 방식”을 선택합니다.</li>\n  <li>업로드가 완료되면 파일이 자동으로 텍스트로 변환되며 편집 플랫폼에서 편집할 수 있습니다.</li>\n  <li>편집 플랫폼에서 번역, AI 분석을 수행한 후 문서 또는 자막을 내보내 동영상 파일에 삽입할 수 있습니다.</li>\n</ul>"
}