module.exports = {
    convert_to_text_title_meta: "在线AI视频分析：自动视频摘要、会议重点、YouTube时间轴",
    convert_to_text_desc_meta: "AI技术提供视频摘要、自动YouTube章节划分及一键下标题功能。专为会议总结和YouTube视频分析而设计，提高内容的可视性和易用性。免费体验，立即注册开始使用！",
    convert_to_text_title:"AI分析",
    convert_to_text_desc:"AI影片摘要、自动Youtube章节、一键下标题",
    start_your_trial:"马上试！",
    hero_img:"./11_Chinese.webp",
   
    three_step_title:"转文字三步骤",
    step_one:"影片摘要及Youtube时间轴",
    step_one_img:"./aianalysis_step1_zh.webp",
    step_one_desc:"从预设范本分析或下指令让AI分析为影片下标题、做总结、甚至提供旁白脚本建议等内容。",
   
    step_two:"快速掌握不同语言的影片",
    step_two_img:"./aianalysis_step2_zh.webp",
    step_two_desc:"按下开始分析后能让一部30分钟的影片透过「AI分析」直接变成短短几百字的并以你熟悉的语言做总结。学习范围瞬间扩及全球！ ",
   
    step_three:"更快速创作影片",
    step_three_img:"./aianalysis_step3_zh.webp",
    step_three_desc:"使用「AI分析」能够迅速提供不同场景适合的标题、总结影片内容并抓出精华、提供Youtube时间轴章节，快速完成影片后制流程。 ",
   
    feature_tutorial_title:'如何使用AI分析功能？ ',
    feature_tutorial_desc:'「AI分析」功能是一个专门设计来深度分析影片内容的强大工具。透过这项功能，使用者可以对上传的影片进行全面的概要总结，或从提供的范本例如：影片总结、AI下标题、Youtube时间轴、Youtube章节等一键分析省去下指令的烦恼。 从而大大提升对影片主旨的理解和互动效率。 ',
    feature_tutorial_poster_url:'./aianalysis_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/Z3hioBKvl0M?autoplay=1',
   
    feature_why_image:'./ai_template_demo_zh.webp',
    feature_extra_title:"AI解析使用秘诀与实例",
    feature_extra_desc:"您可以向AI分析提问各种问题或下指令，例如：<ul><li><strong>Youtube影片标题、章节、时间轴</strong>：「请为这部影片撰写SEO优化过后的YT影片描述，并提供5个建议的耸动标题。」</li><li><strong>快速学习</strong>：「请把这部教学影片的内容分段列出纲要让我能快速学习影片的内容，并且用举例的方式解释较复杂的内容。」</li><li><strong>深度学习</strong>： 「请为第二段的教学内容提供更深入的解释，并且提供范例演示。」</li><li><strong>技术分析</strong>：「请替我找出影片中使用的特效，以及制作该特效合适的软体和教学连结。」</li><li><strong>影片脚本</strong>：「请帮我为这部影片做大约5～10分钟的解说影片脚本，重点放在影片的剧情及作者想要传达的理念。」</li> </ul>「AI分析」可以帮助你总结影片的关键信息、列出影片时间轴、归纳出章节，并回答与影片相关的各种问题。参考以下教学影片：",
   
    feature_what_title:"如何有效对AI下指令？",
    feature_what_desc: "<p>在使用「AI分析」或其他人工智能进行文字处理时，明确具体的沟通非常重要。首先，清楚指出您的具体需求。其次，确定您期望的结果或格式，无论是重写、简化或格式化，以便AI准确理解目标。提供充分的背景信息可以帮助AI更好地把握上下文，从而产生更合适的回答。最后，明确告知希望获得结果的具体形式，确保效率和准确性。这些步骤将帮助您有效地利用AI，确保输出符合您的期望。</p><div style='display: flex; justify-content: space-between;'>\n <div style='width: 50%;'>\n <p><strong>具体要求：</strong></p>\n <ul>\n <li>不明确：帮我修改这段文字。</li>\n <li>明确：请帮我修改这段文字，使其更正式。</li>\n </ul>\n </div>\n <div style='width: 50%;'>\n <p><strong>明确目标：</strong></p>\n <ul>\n <li>不明确：请总结这段文字。</li>\n <li>明确：请将这段500字的文字总结为50字。</li>\n </ul>\n </div>\n</div>\n\n<div style='display: flex; justify-content: space-between;'>\n <div style='width: 50%;'>\n <p><strong>指定格式：</strong></p>\n <ul>\n <li>不明确：请帮我重写这段文字。</li>\n <li>明确：请将这段文字改写成问答形式。</li>\n </ul>\n </div>\n <div style='width: 50%;'>\n <p><strong>提供上下文：</strong></p>\n <ul>\n <li>不明确：请改善这段描述。</li>\n <li>明确：请改善这段产品描述，使其更吸引购买者。</li>\n </ul>\n </div>\n</div>\n\n<div style='display: flex; justify-content: space-between;'>\n <div style='width: 50%;'> </div>"

}