module.exports = {
    convert_to_text_title:"逐字稿人物標記",
    convert_to_text_desc:"上傳手邊的逐字稿，自動標記人物",
    start_your_trial:"馬上試！",
    hero_img:"./10_Chinese.webp",

    step_one:"選擇逐字稿的影音檔",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"先選擇逐字稿對應的影音檔案，文字和影片語言需相同",
    
    step_two:"上傳逐字稿",
    step_two_img:"./transcripts_subtitles_step02_zh.webp",
    step_two_desc:"在視窗 <strong>文字生成方式</strong> 選 <span style=\"color:#3ccebd\">匯入文字檔案</span>，<strong>文字分段方式</strong> 選 <span style=\"color:#3ccebd\">標記人物並分段</span>",

    step_three:"編輯並導出",
    step_three_img:"./export_srt_step03_zh.webp",
    step_three_desc:"進入編輯平台導出",

    feature_tutorial_title:'將已有的逐字稿標記上人物',
    feature_tutorial_desc:'Taption不僅可以將文字轉為字幕，還能自動標記不同人物，使您的內容更具清晰度。這一功能特別適用於多人對話或訪談，它能自動標記每位參與者的發言。觀眾可以更容易區分每個人的發言，提高內容的理解度和吸引力。最重要的是，這一功能節省了大量手動標記不同發言者的時間，讓您能夠立即將文字或逐字稿轉化為包含人物標記的字幕，充分節省您的時間和精力。',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title:"人物標記功能的多元運用",
    feature_extra_desc:"<ul><li><strong>Podcast 製作：</strong>當您擁有 podcast 的逐字稿或錄音文字內容時，使用這個功能可以自動標記不同的說話者，例如主持人和來賓。</li><li><strong>訪談稿整理：</strong>如果您在做訪談或對話內容整理時，有多個參與者的話，自動標記不同說話者可以使整理工作更加高效。您可以輕鬆識別每個參與者的發言，以便在後續整理和分析中更好地理解內容。</li><li><strong>研究和分析：</strong>學者和研究人員可以使用這一功能來分析多人對話內容。透過自動標記說話者，他們可以更輕鬆地跟蹤每個參與者的言論，進行深入的內容分析和研究。</li><li><strong>多人演講：</strong>在會議、研討會或講座等場合，多人演講時，將文字標記不同的發言者可以幫助聽眾更好地理解講者之間的轉換，提高聽眾對內容的理解和注意力。</li><li><strong>媒體報導：</strong>新聞機構可以使用這個功能來整理記者的採訪內容或多人對話，以確保報導的清晰性和精確性。</li></ul>"
}