module.exports = {
  'en-US': '英語 (アメリカ)',
  'en-GB': '英語 (イギリス)',
  'en-AU': '英語 (オーストラリア)',
  'zh-TW': '中国語 (繁体字)',
  'zh': '中国語 (簡体字)',
  'yue-Hant-HK-TW-zh': '広東語 (繁体字中国語を出力)',
  'yue-Hant-HK-zh': '広東語 (出力中国語簡体字)',
  //'zh-CHT': 'Chinese + Taiwanese (Taiwan)',
  'yue-Hant-HK-TW': '広東語 (繁体字)',
  'yue-Hant-HK': '広東語 (簡体字)',
  'es-ES': 'スペイン語 (スペイン)',
  'fr-FR': 'フランス語 (フランス)',
  'de-DE': 'ドイツ語 (ドイツ)',
  'it-IT': 'イタリア語 (イタリア)',
  'pt-PT_pt-BR': 'ポルトガル語 (ポルトガル、ブラジル)',
  'nl-BE': 'オランダ語 (ベルギー)',
  'hi-IN': 'ヒンディー語 (インド)',
  'ja-JP': '日本語 (日本)',
  'ko-KR': '韓国語 (韓国)',
  'vi-VN': 'ベトナム語 (ベトナム)',
  
  'ar-EG': 'アラビア語 (エジプト)',	
  'hy-AM': 'アルメニア語 (アルメニア)',
  'bs-BA': 'ボスニア語 (BiH)',
  'bg-BG': 'ブルガリア語 (ブルガリア)',
  'my-MM': 'ビルマ語 (ミャンマー)',
  'ca-ES': 'カタロニア語 (スペイン)',
  'hr-HR': 'クロアチア語 (クロアチア)',
  'cs-CZ': 'チェコ語 (チェコ共和国)',
  'da-DK': 'デンマーク語 (デンマーク)',
  //'en-IN': '英語 (インド)',
  'tl-PH': 'フィリピン人 (フィリピン)',
 // 'fil-PH': 'フィリピン人 (フィリピン)',
  'fi-FI': 'フィンランド語 (フィンランド)',
  'gl-ES': 'ガリシア語 (スペイン)',
  'el-GR': 'ギリシャ語 (ギリシャ)',
  'gu-IN': 'グジャラート語 (インド)',
  'iw-IL': 'ヘブライ語 (イスラエル)',
  'hu-HU': 'ハンガリー語 (ハンガリー)',
  'id-ID': 'インドネシア語 (インドネシア)',
  'lv-LV': 'ラトビア語 (ラトビア)',
  'lt-LT': 'リトアニア語 (リトアニア)',
  'mk-MK': 'マケドニア語 (北マケドニア)',
  'ms-MY': 'マレー語 (マレーシア)',
  'no-NO': 'ノルウェー語 (ノルウェー)',
  'pl-PL': 'ポーランド語 (ポーランド)',
  'ro-RO': 'ルーマニア語 (ルーマニア)',
  'ru-RU': 'ロシア語 (ロシア)',
  'sk-SK': 'スロバキア語 (スロバキア)',
  'sl-SI': 'スロベニア語 (スロベニア)',
  'sv-SE': 'スウェーデン語 (スウェーデン)',
  'ta-IN': 'タミル語 (インド)',
  'te-IN': 'テルグ語 (インド)',
  'th-TH': 'タイ語 (タイ)',
  'tr-TR': 'トルコ語 (トルコ)',
  'uk-UA': 'ウクライナ語 (ウクライナ)',
  'uz-UZ': 'ウズベク語 (ウズベキスタン)',
  // 'zu-ZA': 'ズールー語 (南アフリカ)'
}
