module.exports = {
    convert_to_text_title:"동영상내 발화자 라벨 붙이기",
    convert_to_text_desc:"동영상 및 음성 파일에서 자동으로 발화자에 라벨을 붙이세요",
    start_your_trial:"지금 무료 시험판을 시작하세요!",
    hero_img:"./04_English.webp",

    step_one:"파일을 업로드",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"사용중인 장치, 유튜브, 구글 드라이브, 혹은 줌에서 파일을 선택하여 업로드할 수 있습니다.",
    
    step_two:"발화자 라벨 붙이기 설정",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"동영상 업로드시 반드시 영상내 언어와 같은 언어를 선택하시고 \"발화자\"별로 구간 나누기를 선택하세요",

    step_three:"완료",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"받아쓰기가 완료되면, 편집 플랫폼에서 발화자를 수정할 수 있습니다.",

    feature_tutorial_title:'동영상내 발화자 라벨 붙이기 관련 튜토리얼',
    feature_tutorial_desc:'자동 발화자 라벨 붙이기를 사용하면 동영상내의 서로 다른 발화자를 빠르게 인식할 수 있습니다. 아래 튜토리얼을 확인하세요!',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"회의 및 웨비나를 받아쓰기하면 어떤 점이 도움되나요?",
    feature_extra_desc:"회의 및 웨비나를 받아쓰기하면 시청자들에게 많은 방법으로 도달할 수 있게 됩니다. 예를 들면,<ul><li>받아쓰기로 인해 정보가 텍스트로 보존됩니다</li><li>회의중 사용된 키워드를 활용하여 SEO를 개선할 수 있습니다</li><li>컨텐츠에 대한 접근성을 훨씬 높입니다</li><li>상품에 관한 팀의 이해도를 높이는 다른 방법을 제공함으로서 ROI를 개선시킵니다</li></ul>"
}