module.exports = {
    convert_mp3_text:'Convert your MP3 to text',
    convert_mp3_desc:'We are the best online speech-to-text audio converter, our leading AI technology delivers your transcription quickly and accurately while keeping the cost low.',
    convert_mp3_now:'Convert your MP3 to Text now',
    common_questions_title: 'Frequently asked questions',
    convert_button_desc1:'15 minutes free',
    convert_button_desc2:'No credit cards required',
    question1:'How to transcribe my video?',
    question2:'How to add subtitles to my video?',
    question2_desc:'Burned-in, Hard-coded subtitles',
    question3:'How to translate my video?',
    question4:'How to increase accuracy by using the AI Dictionary?',
    question5:'How to edit my video by editing text?',
    home:'Home',
    transcribe_now:'Transcribe now',
    taption:'Taption - Automatically generate transcript, translation and subtitles',
    title:'Automatically generate transcript, translation and subtitles | Taption',
    content_title:'Automatically generate transcript, translation and subtitles | Taption',
    content_description:'Feature-rich platform that gives you high-quality transcriptions and subtitles for videos',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com',
    site_name:'Taption'

}