module.exports = {
    home:'Home',
    audiotovideolang:'Convert audio to video',
    speakerlang:'Speaker labeling for videos',
    translatelang:'Translate video',
    aianalysislang:'AI analysis tool',
    addsubtitleslang:'Add subtitles to video',
    converttextlang:'Convert video to text',
    collaboratelang:'Collaborate with teams',
    transcriptstosubtitleslang:'Convert TXT to SRT',
    transcriptstospeakerslang:'Add speaker labeling for existing transcripts',
}