import { initializeApp } from "firebase/app";
import { initializeFirestore} from 'firebase/firestore';
var config = {};
if(process.env.VUE_APP_MODE === 'test'){
    config = {
      apiKey: "AIzaSyD40xfaBqMz47tYV4RKxV_ucjJq7QTc818",
      authDomain: "taption-test.firebaseapp.com",
      databaseURL: "https://taption-test.firebaseio.com",
      projectId: "taption-test",
      storageBucket: "taption-test.appspot.com",
      messagingSenderId: "217962160352",
      appId: "1:217962160352:web:32b108e77fdbdf24a07085",
      measurementId: "G-XG2VZMZMWS"
  };
}
else if(process.env.VUE_APP_MODE === 'production'){
    config = {
      apiKey: "AIzaSyAjj8_XGqJiJ37FvW8pIEohS6lje4tWbv8",
      authDomain: "taption-production.firebaseapp.com",
      databaseURL: "https://taption-production.firebaseio.com",
      projectId: "taption-production",
      storageBucket: "taption-production.appspot.com",
      messagingSenderId: "332476767402",
      appId: "1:332476767402:web:ab65bd209fbe42dcfd84d3",
      measurementId: "G-ZB93L8FZ5Z"
  };
}
else{
  config = {
    apiKey: "AIzaSyD40xfaBqMz47tYV4RKxV_ucjJq7QTc818",
    authDomain: "taption-test.firebaseapp.com",
    databaseURL: "https://taption-test.firebaseio.com",
    projectId: "taption-test",
    storageBucket: "taption-test.appspot.com",
    messagingSenderId: "217962160352",
    appId: "1:217962160352:web:32b108e77fdbdf24a07085",
    measurementId: "G-XG2VZMZMWS"
};
}

const firebaseApp = initializeApp(config)
initializeFirestore(firebaseApp, {experimentalForceLongPolling: true, merge: true})

export {firebaseApp};