module.exports = {
    title_0: '영상을 받아쓰기 하려면 어떻게 하나요?',
    desc_0:'튜토리얼 영상 보기',
    video_0:"https:www.youtube.com/embed/FZOHfAFIqog",
   
    title_1: '영상에 자막을 넣으려면 어떻게 하나요?',
    desc_1:'튜토리얼 영상 보기',
    video_1:"https:www.youtube.com/embed/WEFq3Mcl8dk",
   
    title_2: '영상을 번역하려면 어떻게 하나요?',
    desc_2:'튜토리얼 영상 보기',
    video_2:"https:www.youtube.com/embed/mU4uadAK_w0",
   
    title_3: 'AI맞춤사전를 활용해서 정확도를 높이려면 어떻게 하나요?',
    desc_3:'튜토리얼 영상 보기',
    video_3:"https:www.youtube.com/embed/7gEBb_moIuI",

    title_4: '텍스트를 편집해서 영상을 편집하려면 어떻게 하나요?',
    desc_4:'튜토리얼 영상 보기',
    video_4:"https:www.youtube.com/embed/W2yJEU1vMrg",
    
    title_5: '청구서 및 영수증은 어디에서 확인하나요?',
    desc_5:'구독중인 유저는 <span style="color:rgb(0, 209, 178);">계정 → 청구서 → 전체 보기</span>에서 확인 및 다운로드 가능합니다',
    video_5:"",

    title_6: '유료플랜을 구독중인 유저인데 월 이용시간이 더 필요하면 어떻게 하나요?',
    desc_6:'<span style="color:rgb(0, 209, 178);">프리미엄 플랜</span>을 구독중이신 경우, 추가 이용시간을 <span style="color:rgb(0, 209, 178);">시간당 $6</span>의 할인된 가격에 구매하실 수 있습니다. <span style="color:rgb(0, 209, 178);">엔터프라이즈 플랜</span>을 구독중이신 경우, 추가 이용시간을 <span style="color:rgb(0, 209, 178);">시간당 $3</span>의 할인된 가격에 구매하실 수 있습니다.',
    video_6:"",

    title_7: '받아쓰기가 생성되지 않습니다. 어떻게 하나요?',
    desc_7:'영상 업로드시에 선택한 언어를 확인해주세요. 영상에 사용된 언어와 일치하는 언어를 반드시 선택해야 합니다.',
    video_7:"",

    title_8: '5GB보다 작은 파일을 업로드했는데도 <span style="color:rgb(230, 118, 117);">영상 파일이 너무 큽니다</span>라는 메시지가 계속 뜹니다.',
    desc_8:'5GB 크기 제한은 MP4 포맷 기준입니다. MP4 외의 다른 포맷의 영상을 업로드 하는 경우, MP4로 변환했을때 5GB보다 커질 수 있습니다. 문제를 해결하려면 5GB 이하의 MP4를 업로드해주세요.',
    video_8:"",

    title_9: '에러 메시지의 의미와 에러를 해결하려면 어떻게 해야하는지 알려주세요.',
    desc_9:'<span style="color:rgb(230, 118, 117);">시험판 이용시간 초과</span> : 서비스 이용을 계속하려면 추가 이용시간을 구매하거나 유료플랜을 구독하세요.<br><span style="color:rgb(230, 118, 117);">이용가능시간 부족</span> : 이용시간을 추가구매하거나 유료플랜을 구독하신 후 재시도해주세요.<br><span style="color:rgb(230, 118, 117);">Youtube 연결에 실패했습니다. 다시 시도해주세요</span> : 가장 일반적인 이유는 링크의 유튜브 영상이 최근 업로드되었기 때문입니다. 24시간 이상 기다렸다가 다시 받아쓰기를 시도해주세요.',
    video_9:"",

    title_10: '탭션과 줌을 연동하려면 어떻게 하나요?',
    desc_10:'<a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">단계별 튜토리얼</a>을 확인하세요',
    video_10:"",

    title_11: '다른 질문들이 있습니다. 어떻게 하면 되나요?',
    desc_11:'<span style="color:rgb(0, 209, 178);">help@taption.com</span>로 문의해주세요.',
    video_11:"",

    zoom_title:'Taption + Zoom 연동 단계별 정리',
    zoom_install_desc:'줌 계정을 탭션 계정에 연동할 수 있습니다. 이 튜토리얼을 시청하시면, 줌 녹화파일을 탭션에서 바로 받아쓰기할 수 있게 됩니다.',
    zoom_step_1:'계정에 로그인한 후 "업로드"를 클릭하세요',
    zoom_step_2:'팝업창에서 "줌"을 선택하세요',
    zoom_step_3:'줌 계정으로 로그인하여 권한을 허가하세요',
    zoom_step_4:'목록에서 녹화파일을 선택합니다',
    zoom_step_5:'설정을 조정하고 나면 업로드 및 받아쓰기 준비가 완료됩니다',
    zoom_uninstall_title:'탭션 + 줌 연동 삭제하기',
    zoom_uninstall_desc:'탭션에서 줌을 제거하려면 <strong>계정</strong> → <strong>앱</strong> → <strong>앱 제거</strong>를 선택하세요.<br>문의사항이 있으시면 <span style="color:rgb(0, 209, 178);">help@taption.com</span>로 이메일 문의해주세요',

    webex_title:'Taption + Webex 연동 단계별 정리',
    webex_install_desc:'이제 Webex 계정을 Taption 계정에 연결할 수 있습니다. 이 튜토리얼 후에는 Taption에서 직접 Webex 녹화를 전사할 수 있습니다',
    webex_step_1:'계정에 로그인한 후 "업로드"를 클릭하세요',
    webex_step_2:'팝업창에서 "Webex"을 선택하세요',
    webex_step_3:'Webex 계정으로 로그인하여 권한을 허가하세요',
    webex_step_4:'목록에서 녹화파일을 선택합니다',
    webex_step_5:'설정을 조정하고 나면 업로드 및 받아쓰기 준비가 완료됩니다',
    webex_uninstall_title:'Taption + Webex 연동 삭제하기',
    webex_uninstall_desc:'탭션에서 줌을 제거하려면 <strong>계정</strong> → <strong>앱</strong> → <strong>앱 제거</strong>를 선택하세요.<br>문의사항이 있으시면 <span style="color:rgb(0, 209, 178);">help@taption.com</span>로 이메일 문의해주세요',
}