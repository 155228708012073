module.exports = {
    convert_to_text_title:"チームとコラボしましょう",
    convert_to_text_desc:"クラウド上のチームアカウントで字幕やラベルを共有",
    start_your_trial:"無料で試す",
    hero_img:"./06_English.webp",

    step_one:"マルチユーザーアクセス",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"あなたのアカウントに複数のユーザーを追加および管理できます",
    
    step_two:"字幕の共有と編集",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"あなたのアカウントに複数のユーザーを追加し、字幕や議事録を共有できます",

    step_three:"ユーザー権限管理",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"閲覧や編集などアカウントの権限を管理できます",

    feature_tutorial_title:'ユーザー管理チュートリアル',
    feature_tutorial_custom_desc:'6つの事前設定権限に加えて、アカウント内の各ユーザーに対して各機能へのアクセスをカスタマイズすることもできます。',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'マルチユーザーアカウントで、請求やコスト管理を簡単にして効率を上げましょう。下のチュートリアルを今すぐチェック！',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"複数のユーザーや権限を一つのアカウントで管理するメリット",
    feature_extra_desc:"あなたのアカウントで複数のユーザーを管理することで、アプリ機能を制御できます。ユーザー権限が管理できるメリットは:<ul><li>ユーザーのプラットフォームへの追加がたった数分で簡単になります</li><li>従業員やベンダーに権限を制限でき、Taption上の字幕削除などヒューマンエラーを押さえます</li><li>ユーザーごとにアクセスを制限し、機能ごとの専門家として育成できます</li><li>マネジメントがすべての機能を単独で抑制できます</li></ul>"
}