module.exports = {
    convert_to_text_title:"用戶管理，權限分配",
    convert_to_text_desc:"一個帳號加入多個用戶，共享分鐘和共同編輯來提高效率！",
    start_your_trial:"馬上試！",
    hero_img:"./06_Chinese.webp",

    step_one:"一個帳號多個用戶",
    step_one_img:"./collaborate_step1_zh.webp",
    step_one_desc:"加入多個用戶共同使用帳號，",
    
    step_two:"分鐘，影片共享管理",
    step_two_img:"./collaborate_step2_zh.webp",
    step_two_desc:"管理用戶的分鐘使用，也能讓多個用戶同時編輯",

    step_three:"7種用戶權限",
    step_three_img:"./collaborate_step3_zh.webp",
    step_three_desc:"可以給用戶7種不同的權限",

    feature_tutorial_title:'用戶管理教學',

    feature_tutorial_custom_desc:'除了預設的6種權限，也能客製化的去針對每個用戶的閱覽影片，編輯文字，及相關功能等權限。',
    feature_tutorial_custom_url:'./custom_dialog_zh.webp',
    feature_tutorial_desc:'多個用戶共享不僅可以節省支出，也能大大的增加效率！快來看看下方影片關於用戶管理的介紹吧！',
    feature_tutorial_poster_url:'./permission_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/JTEaovV4k8Q?autoplay=1',

    feature_extra_title:"共享編輯為什麼變成了趨勢？",
    feature_extra_desc:"<ul><li>網路及遠端工作的時代：在網路速度越來越快、遠端工作成為主流的現在，共同作業的概念遷移到了網路上，讓所有參與專案者都能不受空間、時間限制地作業</li><li>各大軟體都有共享編輯功能：I. Google Doc：雲端共享編輯，別人修改哪些內容都能一目了然 II. 微軟：Office系列的如Word、Powerpoint、Excel等編輯軟體，還有Onedrive III. Adobe：Photoshop、illustrator、Premiere Pro……等，從照片、文件到影片都能共同編輯</li><li>利用共享編輯功能的好處：有些人可能覺得可以各自完成作業再整合就好，但每個人工作速度不一樣，有些需要配合他人進度的工作，藉由共享編輯的功能，可以促進整體的工作效率，整個專案、作品的輪廓也更加清晰，而且有任何改動都不用再經過繁複的過程</li></ul>"
}