module.exports = {
    title_0: '動画をテキストに変換する方法は?',
    desc_0: '説明ビデオを参照してください',
    video_0:"https://www.youtube.com/embed/FZOHfAFIqog",

    title_1: '動画に字幕を追加する方法は?',
    desc_1: '説明ビデオを参照してください',
    video_1:"https://www.youtube.com/embed/WEFq3Mcl8dk",
    
    title_2: '動画を翻訳するには?',
    desc_2: '説明ビデオを参照してください',
    video_2:"https://www.youtube.com/embed/mU4uadAK_w0",
    
    title_3: '「AI辞書」と「すべて置換」を使って精度を上げる方法は?',
    desc_3: '説明ビデオを参照してください',
    video_3:"https://www.youtube.com/embed/7gEBb_moIuI",

    title_4: '「動画クリップの字幕編集」機能の使い方は?',
    desc_4: '説明ビデオを参照してください',
    video_4:"https://www.youtube.com/embed/W2yJEU1vMrg",

    title_5: '請求書と領収書をダウンロードするには?',
    desc_5:'購読後、<span style="color:rgb(0, 209, 178);">マイ アカウント → 購読請求書 → 表示</span> からいつでもダウンロードできます',
    video_5:"",
    
    title_6: 'サブスクリプション後、毎月与えられる分数よりも多くの分数が必要です。どうすればよいですか?',
    desc_6:'<span style="color:rgb(0, 209, 178);">プレミアムサブスクリプション</span>に加入すると、割引価格で購入できます<span style="color:rgb(0, 209, 178);" >$6ドル/時間</span> 分を追加します。 <span style="color:rgb(0, 209, 178);">大きいサブスクリプション</span>を申し込むと、<span style="color:rgb(0, 209, 178) の割引価格で購入できます;">$3ドル/時間</span>分を追加します。 ',
    video_6:"",

    title_7: '翻訳は元の言語のままですが、どうすればよいですか?',
    desc_7:'アップロードの最初に選択した言語が、動画の音声の言語と同じであることを確認してください。 (例：英語の動画をアップロードする場合、言語として「英語」を選択してください。完了後、編集プラットフォームの右上に移動して翻訳してください)',
    video_7:"",

    title_8: 'アップロードされたファイルは 5GB 未満ですが、<span style="color:rgb(230, 118, 117);">ビデオが長すぎます</span> というメッセージが表示されるのはなぜですか?',
    desc_8:'5GB は MP4 ファイルに基づいています. 他のタイプ (MOV など) をアップロードすると、サーバーは最初にファイルを MP4 に変換します.このメッセージは、転送されたファイルが 5GB を超える場合に表示されます。このメッセージを回避するには、MP4 に変換してアップロードします。 ',
    video_8:"",
    
    title_9: 'これらのエラー メッセージを修正するには?',
    desc_9:'<span style="color:rgb(230, 118, 117);">試用期間超過</span> : 定期購入するか、さらに分を購入してください<br><span style="color:rgb(230, 118, 117);">分数が足りません</span> : 定期購入するか、さらに分数を購入してください<br><span style="color:rgb(230, 118, 117);">この Youtube リンクは失敗しました。もう一度お試しください</span> > : 通常、YouTube から新しくアップロードされた動画で発生します。再度アップロードする前に、少なくとも24時間お待ちください。',
    video_9:"",

    title_10: 'Taption と Zoom の接続方法は?',
    desc_10:'<a href="https://www.taption.com/blog/en/zoom-tutorial-en" rel="noreferrer" target="_blank">チュートリアル記事</a>を参照してください',
    video_10:"",
    
    
    title_11: 'さらに質問があります。どのように連絡すればよいですか?',
    desc_11: '私たちのメールボックスに手紙を送ってください: <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",

    
    zoom_title: 'Taption を Zoom に接続するには?',
    zoom_install_desc:'Zoom アカウントを Taption とリンクすると、Taption インターフェイスからテキストに変換する必要がある Zoom ミーティングを直接選択できます。 ',
    zoom_step_1: 'ログイン後、「アップロード」をクリック',
    zoom_step_2: 'ビデオ ソースとして Zoom を選択',
    zoom_step_3: 'アカウントにログインし、Zoom ページに入った後に認証します',
    zoom_step_4: '会議ビデオを選択',
    zoom_step_5: '設定後、アップロードしてテキストに変換できます',
    zoom_uninstall_title: 'Taption と Zoom の接続を解除するには?',
    zoom_uninstall_desc:'リンクをキャンセルするか、Zoom アカウントを変更するには、[<strong>マイ アカウント</strong>] → [<strong>接続されたソフトウェア</strong>] → [<strong>リンク解除中</strong>] に移動します<br>詳細についてはご不明な点がございましたら、<span style="color:rgb(0, 209, 178);">help@taption.com</span> までお問い合わせください。',

    webex_title: 'Taption を Webex に接続するには?',
    webex_install_desc:'Webex アカウントを Taption とリンクすると、Taption インターフェイスからテキストに変換する必要がある Webex ミーティングを直接選択できます。 ',
    webex_step_1: 'ログイン後、「アップロード」をクリック',
    webex_step_2: 'ビデオ ソースとして Webex を選択',
    webex_step_3: 'アカウントにログインし、Webex ページに入った後に認証します',
    webex_step_4: '会議ビデオを選択',
    webex_step_5: '設定後、アップロードしてテキストに変換できます',
    webex_uninstall_title: 'Taption と Webex の接続を解除するには?',
    webex_uninstall_desc:'リンクをキャンセルするか、Webex アカウントを変更するには、[<strong>マイ アカウント</strong>] → [<strong>接続されたソフトウェア</strong>] → [<strong>リンク解除中</strong>] に移動します<br>詳細についてはご不明な点がございましたら、<span style="color:rgb(0, 209, 178);">help@taption.com</span> までお問い合わせください。'
}