module.exports = {
    convert_to_text_title:"錄音檔轉影片",
    convert_to_text_desc:"自動將MP3錄音檔加上高清客製化圖片轉為有字幕的MP4影片",
    start_your_trial:"馬上試！",
    hero_img:"./05_Chinese.webp",

    step_one:"將錄音檔轉為文字",
    step_one_img:"./customized_step1_zh.webp",
    step_one_desc:"上傳錄音檔並且以「字幕」形式分段轉成文字",
    
    step_two:"上傳一張高清圖片",
    step_two_img:"./customized_step2_zh.webp",
    step_two_desc:"校正文字後導出選取MP4影片上傳一張作為影片的背景圖片",

    step_three:"導出",
    step_three_img:"./customized_step3_zh.webp",
    step_three_desc:"設定字體及大小後即可導出一部客製化影片",

    feature_tutorial_title:'錄音檔轉客製化影片教學',
    feature_tutorial_desc:'錄音檔轉影片可以在數分鐘內完成，不僅有聽眾也有了觀眾。快來觀看錄音檔轉影片的教學影片：',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/2S_5lKr8Oyk?autoplay=1',

    feature_extra_title:"錄音檔轉影片及輸出成文字檔的便利性",
    feature_extra_desc:"<ul><li>Podcast內容的影片化，對Podcaster而言，能進一步把作品影片化，以精美的圖片作為影片背景，將Podcast內容轉成YT影片，一次圈粉聽眾和觀眾</li><li>對Podcast聽眾來說，可以利用錄音檔輸出成文字的功能，輔以一些便利的筆記APP來整理資訊，對於常聽那些知識型Podcast的聽眾來說非常方便</li><li>迅速產生逐字稿，便於修正內容和製作綱要，也方便把影片做成文章</li><li>便於紀錄並轉換，作家日常口語記錄的靈感、線上或實體課程內容的錄音記錄、會議記錄、Podcast內容……等，錄下來後直接一鍵轉成文字，省時省力</li></ul>"
}