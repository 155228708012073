module.exports = {
    convert_to_text_title:"音声を動画に変換しましょう",
    convert_to_text_desc:"音声ファイルと画像を使って字幕のついたMP4動画を簡単に作成できます",
    start_your_trial:"無料で試す",
    hero_img:"./05_English.webp",

    step_one:"音声を動画に変換します",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"音声ファイルをアップロードし、 \"Captions/Subtitles\"セクションごとにカテゴリーを選びます",
    
    step_two:"カスタム画像をアップロードします",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"編集プラットフォームで抽出→MP4動画ファイルを選択して画像をアップロードします",

    step_three:"動画を抽出します",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"あなただけのカスタム動画を抽出します！",

    feature_tutorial_title:'音声を動画に変換するためのチュートリアルです',
    feature_tutorial_desc:'音声を数分内に動画に変換し、視聴者を更に増やしましょう。下のチュートリアルを今すぐチェック！',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"音声ファイルを字幕付き動画に変換して得られる効果は？",
    feature_extra_desc:"たった数分で音声ファイルを字幕付き動画に変換できます。変換した動画を用いて:<ul><li>様々なソーシャルメディア上に異なる目的で再掲できます</li><li>視聴者やフォロワーとのユニークなコミュニケーション窓口になります</li><li>海外の視聴者にも音声や動画コンテンツを届けられます</li></ul>"
}