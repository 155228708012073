module.exports = {
    convert_to_text_title:"팀원들과 협업하기",
    convert_to_text_desc:"클라우드 기반 팀 계정으로 받아쓰기 및 라벨을 공유하세요",
    start_your_trial:"지금 무료 시험판을 시작하세요!",
    hero_img:"./06_English.webp",

    step_one:"다중 사용자 액세스",
    step_one_img:"./collaborate_step1_en.webp",
    step_one_desc:"하나의 계정에 여러 사용자를 추가하고 관리하세요",
    
    step_two:"받아쓰기 공유 및 편집",
    step_two_img:"./collaborate_step2_en.webp",
    step_two_desc:"하나의 계정에 여러 사용자를 추가해서 받아쓰기 및 메모를 공유하세요",

    step_three:"유저 권한 관리",
    step_three_img:"./collaborate_step3_en.webp",
    step_three_desc:"각 계정의 읽기 및 편집 권한을 관리하세요",

    feature_tutorial_title:'유저 관리 튜토리얼',
    feature_tutorial_custom_desc:'6개의 사전 설정된 권한 외에도, 계정 내 모든 사용자에 대해 각 기능에 대한 액세스를 사용자 정의할 수 있습니다.',
    feature_tutorial_custom_url:'./custom_dialog_en.webp',
    feature_tutorial_desc:'다중 사용자 접근가능 계정은 청구서 발행 및 지불하기가 쉬워지며 비용 절감 및 업무 생산성 향상으로 이어집니다. 아래 튜토리얼을 지금 확인하세요!',
    feature_tutorial_poster_url:'./permission_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/z1v2YdADBW8?autoplay=1',

    feature_extra_title:"하나의 계정에서 여러 사용자 및 권한을 관리하는 이점",
    feature_extra_desc:"하나의 계정에서 여러 사용자를 관리하면 앱 기능을 중앙에서 통제할 수 있습니다. 또한 유저별로 권한을 나누면 이런 이점도 있습니다<ul><li>플랫폼에 유저를 새로 추가하기가 쉬워집니다</li><li>직원 및 하청업체 등의 접근을 제한하여 탭션에서 작성된 받아쓰기 등을 실수로 삭제하는 사고 등을 미연에 방지합니다</li><li>유저별로 권한을 제한함으로서 특정 기능에 대한 전문가로 성장할 수 있습니다</li><li>경영진만이 모든 기능에 대한 통제력을 유지할 수 있습니다</li></ul>"
}