module.exports = {
    convert_to_text_title_meta: "온라인 번역 소프트웨어: 자동 동영상 번역",
    convert_to_text_desc_meta: "온라인 자동 번역으로 MP4 동영상, MP3 오디오 또는 YouTube 동영상을 텍스트로 변환하고 40개 이상의 언어로 번역합니다. 이중 언어 자막을 동영상에 삽입하여 내보내기도 지원합니다. 무료로 체험해보세요. 지금 가입하고 사용을 시작하세요!",
    convert_to_text_title: "자동 동영상 번역",
    convert_to_text_desc: "온라인 자동 번역으로 MP4 동영상, MP3 오디오, YouTube 동영상을 텍스트로 변환한 후 40개 이상의 언어로 번역합니다. 동영상 자막 제작의 마법사입니다!",
    start_your_trial: "지금 시작하세요!",
    hero_img: "./03_Chinese.webp",

    step_one: "오디오 및 비디오를 텍스트로 변환",
    step_one_img: "./select_source_zh.webp",
    step_one_desc: "번역 전에 동영상을 텍스트로 변환합니다. 업로드 시 선택한 언어는 동영상 자체의 언어와 동일해야 합니다. 이 과정은 음성-텍스트 변환 기술을 포함하며 MP4, MOV 등 다양한 형식의 동영상을 텍스트로 변환할 수 있습니다.",

    step_two: "번역 언어 선택",
    step_two_img: "./translate_step2_zh.webp",
    step_two_desc: "텍스트를 수정한 후 편집 플랫폼 오른쪽 상단에서 번역을 진행할 수 있습니다. 번역 전 원문을 수정하면 더 나은 번역 결과를 얻을 수 있습니다. 영어, 중국어, 일본어, 한국어, 인도네시아어, 베트남어 등 40개 이상의 언어 번역을 지원합니다.",

    step_three: "편집 후 내보내기",
    step_three_img: "./translate_step3_zh.webp",
    step_three_desc: "번역 후 대조 플랫폼에서 조정한 후 내보낼 수 있습니다! MP4 동영상 파일, SRT 자막 파일, TXT 스크립트 등 다양한 형식을 지원합니다.",

    feature_tutorial_title: '동영상 번역 튜토리얼',
    feature_tutorial_desc: '<p>Taption의 동영상 번역 소프트웨어는 전통적인 자막 번역 과정에서 흔히 발생하는 문제를 해결하기 위해 설계되었습니다. 우리의 기술은 다음 세 가지 주요 과제를 극복하여 번역 품질과 시청 경험을 보장합니다:</p><ul><li><strong>시간 동기화:</strong> 정확한 교정으로 번역된 자막과 동영상 시간을 완벽하게 동기화합니다.</li><li><strong>내용 흐름:</strong> 자막의 연속성을 개선하여 원활한 시청 경험을 제공합니다.</li><li><strong>의미 정확성:</strong> 직역으로 인한 의미 편차를 피하고 정확한 정보를 전달합니다.</li></ul><p>영어, 일본어, 한국어, 베트남어 등 40개 이상의 언어를 지원하는 Taption은 이중 언어 자막을 쉽게 만들고 내보낼 수 있게 해주어 다국어 동영상 번역 요구 사항을 모두 충족시킵니다. 우리의 온라인 플랫폼을 통해 소프트웨어를 다운로드할 필요 없이 이 기능을 바로 사용할 수 있습니다. 동영상 제작자, 교육자 또는 국제 회의 주최자 등 누구든지 Taption의 동영상 번역 소프트웨어가 최선의 선택입니다. 단순한 온라인 번역 소프트웨어라기보다는 사용해본 사용자들이 온라인 번역의 마법사라고 부를 정도입니다!</p>MP4나 MP3 번역에 관계없이 우리의 편집 플랫폼은 전체 과정을 더욱 원활하고 시간 효율적으로 만듭니다. 이중 언어 자막을 원하시면 이 <a class="link" href="https://www.taption.com/add-subtitles/zh">이중 언어 자막 튜토리얼</a>을 참조하세요. 다음의 자동 번역 튜토리얼 동영상을 확인해보세요:',
    feature_tutorial_poster_url: './translate_tutorial_poster.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/3KbT6st8nYU?autoplay=1',

    feature_extra_title: "자주 묻는 질문",
    feature_extra_desc: "<ul><li><strong>어떤 유형의 동영상 형식을 번역할 수 있나요?</strong><p>Taption은 편리한 자동 변환 기능을 제공하여 mov, avi, wmv, flv, mpeg, ogg, mp4, m4a, wav 및 mp3를 포함한 다양한 동영상 및 오디오 형식을 지원하여 다양한 요구 사항을 충족합니다. 또한 Webex, Zoom 회의, Google Drive 오디오 및 비디오 파일, YouTube 동영상의 다운로드 및 변환 후 번역 기능을 직접 지원합니다.</p></li><li><strong>동영상 번역에 얼마나 시간이 걸리나요?</strong><p>Taption에서 동영상 번역 시간은 일반적으로 매우 빠르며, 대부분의 경우 1분 이내에 번역 과정이 완료됩니다. 구체적인 시간은 동영상의 길이와 복잡성에 따라 다를 수 있습니다.</p></li><li><strong>동영상 번역의 요금 기준은 어떻게 되나요?</strong><p>Taption의 동영상 번역 및 자막 생성에 대한 독특한 요금 체계는 동영상 총 길이의 절반에 해당하는 분 수를 차감하는 방식입니다. 예를 들어, YouTube 동영상의 길이가 20분이라면 사용자 계정에서 10분의 분 수가 차감됩니다. 이 요금 체계는 영한 번역, 일본어 번역, 한국어 번역, 인도네시아어 번역, 베트남어 번역 등 다양한 동영상 번역 서비스에 적용되며, 사용자에게 비용 효율적인 온라인 번역 서비스를 제공하는 것을 목표로 합니다.</p></li></ul>"
}