module.exports = {
    convert_to_text_title:"Convert audio to video",
    convert_to_text_desc:"Take your audio file and an image to convert into a MP4 video with captions",
    start_your_trial:"Start your free trial!",
    hero_img:"./05_English.webp",

    step_one:"Convert audio to text",
    step_one_img:"./customized_step1_en.webp",
    step_one_desc:"Upload your audio and choose segment each sections for \"Captions/Subtitles\"",
    
    step_two:"Upload custom image",
    step_two_img:"./customized_step2_en.webp",
    step_two_desc:"In the editing platform, upload your image under Export → MP4 Video File",

    step_three:"Export video",
    step_three_img:"./customized_step3_en.webp",
    step_three_desc:"Export your customized video!",

    feature_tutorial_title:'How to turn your audio into video ',
    feature_tutorial_desc:'So, you\'ve got an audio file and you\'re thinking of converting it to video. This might seem like just a simple task – but it’s way more than that! It\'s a smart move that could broaden your content audience considerably. Our easy-to-navigate editing platform makes the whole process as simple and as convenient as possible. <br><br>Guess what? We\'ve got a detailed tutorial ready to guide you, so feel free to check it out for a better understanding of how to get started. ',
    feature_tutorial_poster_url:'./audio_to_video_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/efDzKWNMgxw?autoplay=1',

    feature_extra_title:"Why should you switch your audio file to an MP4 video with on-screen text?",
    feature_extra_desc:"Have you ever thought of taking your audio files to the next level by converting them into MP4 videos complete with captions or subtitles? Here’s why this is a smart move: <ul><li><strong>Content Repurposing:</strong> One major plus is the chance to reuse your content across various social media platforms. This extends your reach and makes the most out of what you've already created.</li><li><strong>Unique Interaction:</strong> The addition of captions or subtitles is a fresh, inclusive way to connect with your content audience. </li><li><strong>Reach Out to the World:</strong> When you convert audio to video, you're not just changing formats - you're making your content available to a worldwide audience. With transcription and subtitles in the mix, your message knows no borders. </li></ul>By tapping into these benefits, you're stepping up your overall content game. These are some pretty good reasons to convert your audio to video, don’t you think?"
}