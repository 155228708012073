module.exports = {
    convert_to_text_title_meta: "オンライン翻訳ソフト：自動動画翻訳",
    convert_to_text_desc_meta: "MP4動画、MP3音声、YouTubeビデオを自動的にテキストに変換し、40以上の言語に翻訳。さらに、バイリンガル字幕を動画に埋め込んでエクスポートできます。無料トライアルで今すぐ始めましょう！",
    convert_to_text_title: "自動動画翻訳",
    convert_to_text_desc: "MP4動画、MP3音声、YouTube動画をテキストに変換し、40以上の言語に翻訳。動画字幕作成の強力なツールです！",
    start_your_trial: "今すぐ試す！",
    hero_img: "./03_Chinese.webp",

    step_one: "音声をテキストに変換",
    step_one_img: "./select_source_zh.webp",
    step_one_desc: "翻訳前に動画をテキストに変換します。アップロード時に選択する言語は動画の原語と同じにしてください。この過程では音声認識技術を使用し、MP4、MOVなど様々な形式の動画をテキストに変換します。",

    step_two: "翻訳言語を選択",
    step_two_img: "./translate_step2_zh.webp",
    step_two_desc: "テキストを校正した後、編集プラットフォームの右上から翻訳を行います。翻訳前に原文を校正することで、より良い翻訳結果が得られます。英語、中国語、日本語、韓国語、インドネシア語、ベトナム語など40以上の言語に対応しています。",

    step_three: "編集してエクスポート",
    step_three_img: "./translate_step3_zh.webp",
    step_three_desc: "翻訳後、対照プラットフォームで調整し、エクスポートできます。MP4動画ファイル、SRT字幕ファイル、TXTスクリプトなど、多様な形式に対応しています。",

    feature_tutorial_title: '動画翻訳チュートリアル',
    feature_tutorial_desc: '<p>Taptionの動画翻訳ソフトは、従来の字幕翻訳プロセスにおける一般的な問題を解決するために設計されました。以下の3つの主要な課題を克服し、翻訳品質と視聴体験を保証します：</p><ul><li><strong>タイミング同期：</strong>精密な調整により、翻訳後の字幕が動画のタイミングと完璧に同期します。</li><li><strong>内容の流暢さ：</strong>字幕の一貫性を向上させ、スムーズな視聴体験を提供します。</li><li><strong>意味の正確さ：</strong>直訳による意味のずれを避け、正確な情報を伝達します。</li></ul><p>英語、日本語、韓国語、ベトナム語など40以上の言語をサポートし、Taptionを使えば簡単にバイリンガル字幕を作成・エクスポートできます。多言語動画翻訳のニーズを全面的に満たします。オンラインプラットフォームを通じて、ソフトウェアのダウンロードなしで即座に利用開始できます。動画制作者、教育者、国際会議の主催者など、様々なユーザーにとってTaptionの動画翻訳ソフトは最適な選択肢です。単なるオンライン翻訳ソフトではなく、使用経験のあるユーザーからは「オンライン翻訳の神ツール」と呼ばれています！</p>MP4やMP3の翻訳においても、当社の編集プラットフォームがプロセス全体をよりスムーズで時間効率の良いものにします。バイリンガル字幕を希望する場合は、<a class="link" href="https://www.taption.com/add-subtitles/zh">バイリンガル字幕のチュートリアル</a>をご参照ください。以下の自動翻訳チュートリアル動画をご覧ください：',
    feature_tutorial_poster_url: './translate_tutorial_poster.webp',
    feature_tutorial_youtube: 'https://www.youtube.com/embed/3KbT6st8nYU?autoplay=1',

    feature_extra_title: "よくある質問",
    feature_extra_desc: "<ul><li><strong>どのような動画形式を翻訳できますか？</strong><p>Taptionは便利な自動変換機能を提供し、mov、avi、wmv、flv、mpeg、ogg、mp4、m4a、wav、mp3など多様な動画・音声形式に対応しています。さらに、Webex、Zoom会議、Google Driveの音声・動画ファイル、YouTubeビデオの直接ダウンロードと変換後の翻訳機能もサポートしています。</p></li><li><strong>動画翻訳にはどのくらい時間がかかりますか？</strong><p>Taptionでの動画翻訳は通常非常に速く、ほとんどの場合1分以内に完了します。具体的な時間は動画の長さや複雑さによって異なる場合があります。</p></li><li><strong>動画翻訳の料金体系はどのようになっていますか？</strong><p>Taptionの動画翻訳と字幕生成サービスでは、動画の総時間の半分の分数を課金する独自の料金システムを採用しています。例えば、20分のYouTube動画の場合、ユーザーアカウントから10分の分数が差し引かれます。この料金体系は、英語から日本語、日本語から英語、韓国語、インドネシア語、ベトナム語など、様々な言語の翻訳サービスに適用され、ユーザーにコスト効率の高いオンライン翻訳サービスを提供することを目的としています。</p></li></ul>"

    // SEO keywords: 動画翻訳, オンライン翻訳, 自動字幕生成, バイリンガル字幕, 多言語対応, 音声認識, 字幕同期, 翻訳ソフト, YouTubeビデオ翻訳, 国際化コンテンツ
}