module.exports = {
'en':'英語',
'zh-TW':'中文(繁體)',
// 'zh-CHT':'中文(繁體)',
'zh':'中文(簡體)',
'yue-Hant-HK': '粵語',
'yue-Hant-HK-TW': '粵語',
'ja':'日語',
'ko':'韓文',
'es':'西班牙語',
'de':'德語',
'fr':'法語',
'af':'南非荷蘭語',
'sq':'阿爾巴尼亞語',
'am':'阿姆哈拉語',
'ar':'阿拉伯語',
'hy':'亞美尼亞語',
'az':'阿塞拜疆人',
'eu':'巴斯克語',
'be':'白俄羅斯語',
'bn':'孟加拉',
'bs':'波斯尼亞語',
'bg':'保加利亞語',
'ca':'加泰羅尼亞語',
'co':'科西嘉人',
'hr':'克羅埃西亞',
'cs':'捷克',
'da':'丹麥語',
'nl':'荷蘭語',
'fi':'芬蘭語',
'el':'希臘語',
'gu':'古吉拉特語',
'he':'希伯來語',
'hi':'印地語',
'hu':'匈牙利語',
'id':'印尼文',
'ga':'愛爾蘭',
'it':'意大利語',
'la':'拉丁',
'lv':'拉脫維亞語',
'lt':'立陶宛語',
'ms':'馬來語',
'no':'挪威文',
'pl':'波蘭語',
'pt':'葡萄牙語（巴西,葡萄牙）',
'ro':'羅馬尼亞語',
'ru':'俄語',
'sr':'塞爾維亞文',
'sk':'斯洛伐克',
'sl':'斯洛文尼亞語',
'sv':'瑞典語',
'te':'泰盧固語',
'th':'泰國',
'tr':'土耳其語',
'uk':'烏克蘭語',
'uz':'烏茲別克',
'vi':'越南',
'yi':'意第緒語',
'zu':'祖魯語'
  }
  