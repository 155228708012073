module.exports = {
    convert_to_text_title:"Speaker labeling for videos",
    convert_to_text_desc:"Automatically labeling for your videos and audios.",
    start_your_trial:"Start your free trial!",
    hero_img:"./04_English.webp",

    step_one:"Upload your file",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Upload your file from a one of the following sources: Device, Youtube, Google Drive or Zoom.",
    
    step_two:"Configure for speaker labeling ",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"Make sure to choose the lanuguage matching the video when uploading and choose segment each sections for \"speaker\"",

    step_three:"Complete",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"After the transcription completes, you will be able to make adjustments to speakers in the editing platform.",

    feature_tutorial_title:'Add easy speaker labeling for your videos',
    feature_tutorial_desc:'Are you ready to bring some clarity to your audio or video files? Our automatic speaker labeling feature makes it a breeze for your viewers to spot and tag different speakers. This helps keep your content neat and easy to follow at all times.<br><br>Want to see how it\'s done? We\'ve got a handy video tutorial that can help guide you through each stage.',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"Why transcribing meetings and web conferences makes sense",
    feature_extra_desc:"You might think meeting transcription is just for record-keeping. Think again. Let's delve into the multiple ways transcriptions can elevate your content: <ul><li><strong>Information Retention:</strong> Don't miss a beat. Capture all the details of your meeting to make sure nothing slips through the cracks. </li><li><strong>SEO Optimization:</strong> Incorporate pertinent keywords into your transcriptions and watch your SEO rankings improve. </li><li><strong>Content Accessibility:</strong> With automatic transcription, you're not just logging words; you're making them available to a more expansive audience - including those with hearing impairments.</li><li><strong>ROI:</strong> Enhance your Return on Investment by providing attendees an alternative way to go over the discussed ideas or products, potentially leading to conversions.</li></ul>So, what are you gaining? Well, you're boosting audience engagement and making your content more inclusive. It’s time to take advantage of these benefits and make your videos and meetings as professional and useful as possible."
}