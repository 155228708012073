module.exports = {
  'en': '英語',
  'zh-TW':'中国語 (繁体字)',
  // 'zh-CHT':'中国語 (繁体字)',
  'zh':'中国語 (簡体字)',
  'yue-Hant-HK': '広東語',
  'yue-Hant-HK-TW': '広東語',
  'ja': '日本語',
  'ko': '韓国語',
  'es': 'スペイン語',
  'de': 'ドイツ語',
  'fr': 'フランス語',
  'af': 'アフリカーンス語',
  'sq': 'アルバニア語',
  'am': 'アムハラ語',
  'ar': 'アラビア語',
  'hy': 'アルメニア語',
  'az': 'アゼルバイジャン語',
  'eu': 'バスク語',
  'be': 'ベラルーシ語',
  'bn': 'ベンガル語',
  'bs': 'ボスニア語',
  'bg': 'ブルガリア語',
  'ca': 'カタロニア語',
  'co': 'コルシカ島',
  'hr': 'クロアチア語',
  'cs': 'チェコ語',
  'da': 'デンマーク語',
  'nl': 'オランダ語',
  'fi': 'フィンランド語',
  'el': 'ギリシャ語',
  'gu': 'グジャラート語',
  '彼': 'ヘブライ語',
  'こんにちは': 'ヒンディー語',
  'hu': 'ハンガリー語',
  'id': 'インドネシア語',
  'ga': 'アイルランド',
  'それ': 'イタリア語',
  'la': 'ラテン語',
  'lv': 'ラトビア語',
  'lt': 'リトアニア語',
  'ms': 'マレー語',
  'no': 'ノルウェー語',
  'pl': 'ポーランド語',
  'pt': 'ポルトガル語 (ブラジル、ポルトガル)',
  'ro': 'ルーマニア語',
  'ru': 'ロシア語',
  'sr': 'セルビア語',
  'sk': 'スロバキア',
  'sl': 'スロベニア語',
  'sv': 'スウェーデン語',
  'te': 'テルグ語',
  'th': 'タイ',
  'tr': 'トルコ語',
  'uk': 'ウクライナ語',
  'uz': 'ウズベク語',
  'vi': 'ベトナム',
  'yi': 'イディッシュ語',
  'zu': 'ズールー'
    }