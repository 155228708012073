module.exports = {
    convert_to_text_title_meta:"線上AI影片分析：自動影片摘要、會議重點、Youtube時間軸",
    convert_to_text_desc_meta:"AI技術提供影片摘要、自動YouTube章節劃分及一鍵下標題功能。專為會議總結和YouTube影片分析而設計，提高內容的可視性和易用性。免費體驗，立即註冊開始使用！",
    convert_to_text_title:"AI分析",
    convert_to_text_desc:"AI影片摘要、自動Youtube章節、一鍵下標題",
    start_your_trial:"馬上試！",
    hero_img:"./11_Chinese.webp",

    three_step_title:"轉文字三步驟",
    step_one:"影片摘要及Youtube時間軸",
    step_one_img:"./aianalysis_step1_zh.webp",
    step_one_desc:"從預設範本分析或下指令讓AI分析為影片下標題、做總結、甚至提供旁白腳本建議等內容。",
    
    step_two:"快速掌握不同語言的影片",
    step_two_img:"./aianalysis_step2_zh.webp",
    step_two_desc:"按下開始分析後能讓一部30分鐘的影片透過「AI分析」直接變成短短幾百字的並以你熟悉的語言做總結。學習範圍瞬間擴及全球！",

    step_three:"更快速創作影片",
    step_three_img:"./aianalysis_step3_zh.webp",
    step_three_desc:"使用「AI分析」能夠迅速提供不同場景適合的標題、總結影片內容並抓出精華、提供Youtube時間軸章節，快速完成影片後製流程。",

    feature_tutorial_title:'如何使用AI分析功能？',
    feature_tutorial_desc:'「AI分析」功能是一個專門設計來深度分析影片內容的強大工具。透過這項功能，使用者可以對上傳的影片進行全面的概要總結，或從提供的範本例如：影片總結、AI下標題、Youtube時間軸、Youtube章節等一鍵分析省去下指令的煩惱。 從而大大提升對影片主旨的理解和互動效率。',
    feature_tutorial_poster_url:'./aianalysis_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/Z3hioBKvl0M?autoplay=1',

    feature_why_image:'./ai_template_demo_zh.webp',
    feature_extra_title:"AI解析使用秘訣與實例",
    feature_extra_desc:"您可以向AI分析提問各種問題或下指令，例如：<ul><li><strong>Youtube影片標題、章節、時間軸</strong>：「請為這部影片撰寫SEO優化過後的YT影片描述，並提供5個建議的聳動標題。」</li><li><strong>快速學習</strong>：「請把這部教學影片的內容分段列出綱要讓我能快速學習影片的內容，並且用舉例的方式解釋較複雜的內容。」</li><li><strong>深度學習</strong>： 「請為第二段的教學內容提供更深入的解釋，並且提供範例演示。」</li><li><strong>技術分析</strong>：「請替我找出影片中使用的特效，以及製作該特效合適的軟體和教學連結。」</li><li><strong>影片腳本</strong>：「請幫我為這部影片做大約5～10分鐘的解說影片腳本，重點放在影片的劇情及作者想要傳達的理念。」</li></ul>「AI分析」可以幫助你總結影片的關鍵信息、列出影片時間軸、歸納出章節，並回答與影片相關的各種問題。參考以下教學影片：",

    feature_what_title:"如何有效對AI下指令？",
    feature_what_desc: "<p>在使用 「AI分析」 或其他人工智慧進行文字處理時，明確具體的溝通非常重要。首先，清楚指出您的具體需求。其次，確定您期望的結果或格式，無論是重寫、簡化或格式化，以便 AI 準確理解目標。提供充分的背景資訊可以幫助 AI 更好地把握上下文，從而產生更合適的回答。最後，明確告知希望獲得結果的具體形式，確保效率和準確性。這些步驟將幫助您有效地利用 AI，確保輸出符合您的期望。</p><div style='display: flex; justify-content: space-between;'>\n  <div style='width: 50%;'>\n    <p><strong>具體要求：</strong></p>\n    <ul>\n      <li>不明確：幫我修改這段文字。</li>\n      <li>明確：請幫我修改這段文字，使其更正式。</li>\n    </ul>\n  </div>\n  <div style='width: 50%;'>\n    <p><strong>明確目標：</strong></p>\n    <ul>\n      <li>不明確：請總結這段文字。</li>\n      <li>明確：請將這段500字的文字總結為50字。</li>\n    </ul>\n  </div>\n</div>\n\n<div style='display: flex; justify-content: space-between;'>\n  <div style='width: 50%;'>\n    <p><strong>指定格式：</strong></p>\n    <ul>\n      <li>不明確：請幫我重寫這段文字。</li>\n      <li>明確：請將這段文字改寫成問答形式。</li>\n    </ul>\n  </div>\n  <div style='width: 50%;'>\n    <p><strong>提供上下文：</strong></p>\n    <ul>\n      <li>不明確：請改善這段描述。</li>\n      <li>明確：請改善這段產品描述，使其更吸引購買者。</li>\n    </ul>\n  </div>\n</div>\n\n<div style='display: flex; justify-content: space-between;'>\n  <div style='width: 50%;'> </div>",
}