module.exports = {
    convert_mp3_text:'MP3 파일을 텍스트로 변환하세요',
    convert_mp3_desc:'탭션은 최고의 온라인 자동음성인식 음성 변환기로서, 최첨단 AI 기술이 적은 비용으로도 받아쓰기를 빠르고 정확하게 도와줍니다.',
    convert_mp3_now:'지금 MP3 파일을 텍스트로 변환해보세요',
    common_questions_title: '자주 묻는 질문',
    convert_button_desc1:'15분 무료',
    convert_button_desc2:'결제정보 제공없이 무료 시작',
    question1:'영상을 받아쓰기하려면 어떻게 하나요?',
    question2:'영상에 자막을 추가하려면 어떻게 하나요?',
    question2_desc:'동영상 자체에 자막 삽입하기',
    question3:'영상을 번역하려면 어떻게 하나요?',
    question4:'AI맞춤사전을 사용해 정확도를 높이려면 어떻게 하나요?',
    question5:'텍스트 편집으로 동영상을 편집하려면 어떻게 하나요?',
    home:'홈 화면으로',
    transcribe_now:'지금 받아쓰기 시작',
    taption:'탭션 - 자동으로 받아쓰기 및 번역과 자막을 생성합니다',
    title:'자동으로 받아쓰기, 번역, 자막까지 생성하세요 | 탭션',
    content_title:'자동으로 받아쓰기, 번역, 자막까지 생성하세요 | 탭션',
    content_description:'탭션의 최첨단 AI 기술이 음성 및 영상 파일을 40+개 이상 지원되는 텍스트로 변환합니다. 탭션은 2개 국어까지 동영상 내에 자체적으로 자막을 삽입할 수 있으며, 회의중 발화자 라벨 붙이기, 받아쓰기 내용 번역, 그 외 많은 기능을 제공합니다. 지금 회원가입하고 무료 시험판을 사용해보세요!',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com/kr',
    site_name:'Taption'

}