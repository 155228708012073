module.exports = {
  'en-US': 'English (United States)',
  'en-GB': 'English (United Kingdom)',
  'en-AU': 'English (Australia)',
  'zh-TW': 'Chinese (Taiwan)',
  'zh': 'Chinese (China)',
  'yue-Hant-HK-TW-zh': 'Cantonese (Traditional Chinese Output)',
  'yue-Hant-HK-zh': 'Cantonese (Simplified Chinese Output)',
  'yue-Hant-HK-TW': 'Cantonese (Traditional Cantonese Output)',
  'yue-Hant-HK': 'Cantonese (Simplified Cantonese Output)',
  //'zh-CHT': 'Chinese + Taiwanese (Taiwan)',
  'es-ES': 'Spanish (Spain)',
  'fr-FR': 'French (France)',
  'de-DE': 'German (Germany)',
  'it-IT': 'Italian (Italy)',
  'pt-PT_pt-BR': 'Portuguese (Portugal,Brazil)',
  'nl-BE': 'Dutch (Belgium)',
  'hi-IN': 'Hindi (India)',//
  'ja-JP': 'Japanese (Japan)',
  'ko-KR': 'Korean (South Korea)',
  'vi-VN': 'Vietnamese (Vietnam)',//

  'ar-EG': 'Arabic (Egypt)',	//
  'hy-AM': 'Armenian (Armenia)',
  'bs-BA': 'Bosnian (BiH)', //
  'bg-BG': 'Bulgarian (Bulgaria)',//
  'my-MM': 'Burmese (Myanmar)	',
  'ca-ES': 'Catalan (Spain)',//
  'hr-HR': 'Croatian (Croatia)',//
  'cs-CZ': 'Czech (Czech Republic)',//
  'da-DK': 'Danish (Denmark)',//
  //'en-IN': 'English (India)',
  //'fil-PH': 'Filipino (Philippines)',
  'tl-PH': 'Filipino (Philippines)',//
  'fi-FI': 'Finnish (Finland)',//
  'gl-ES': 'Galician (Spain)',
  'el-GR': 'Greek (Greece)',//
  'gu-IN': 'Gujarati (India)',
  'iw-IL': 'Hebrew (Israel)',//
  'hu-HU': 'Hungarian (Hungary)',//
  'id-ID': 'Indonesian (Indonesia)',//
  'lv-LV': 'Latvian (Latvia)',
  'lt-LT': 'Lithuanian (Lithuania)',
  'mk-MK': 'Macedonian (North Macedonia)',//
  'ms-MY': 'Malay (Malaysia)',//
  'no-NO': 'Norwegian (Norway)',//
  'pl-PL': 'Polish (Poland)', //
  'ro-RO': 'Romanian (Romania)',//
  'ru-RU': 'Russian (Russia)',//
  'sk-SK': 'Slovak (Slovakia)',//
  'sl-SI': 'Slovenian (Slovenia)',
  'sv-SE': 'Swedish (Sweden)',//
  'ta-IN': 'Tamil (India)',//
  'te-IN': 'Telugu (India)',
  'th-TH': 'Thai (Thailand)',//
  'tr-TR': 'Turkish (Turkey)',//
  'uk-UA': 'Ukrainian (Ukraine)',//
  'uz-UZ': 'Uzbek (Uzbekistan)'
 // 'zu-ZA': 'Zulu (South Africa)'
}
