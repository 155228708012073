module.exports = {
    home:'首頁',
    audiotovideolang:'錄音檔轉影片',
    speakerlang:'影音人物標記',
    translatelang:'自動影片翻譯',
    aianalysislang:'AI分析工具',
    addsubtitleslang:'自動影片上字幕',
    converttextlang:'影音轉為逐字稿',
    collaboratelang:'用戶管理和權限分配',
    transcriptstosubtitleslang:'自動TXT轉SRT字幕',
    transcriptstospeakerslang:'逐字稿人物標記',
}