module.exports = {
    convert_to_text_title:"Transcripts to Speakers",
    convert_to_text_desc:"Automatically add speaker labeling for your existing transcripts",
    start_your_trial:"Try it now!",
    hero_img:"./10_English.webp",

    step_one:"Upload your media file",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Select the audio or video file that matches the transcript you have",
    
    step_two:"Upload your transcript file",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"Select <span style=\"color:#3ccebd\"><strong>Import text file</strong></span> for <strong>Transcribing method</strong> and <span style=\"color:#3ccebd\"><strong>Speaker labeling</strong></span> for <strong>Select your output format</strong>",

    step_three:"Edit and export",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"Once complete head into the editing platform to update the speaker namings or translate. Export it when ready! ",

    feature_tutorial_title:'Automated speaker labeling for enhanced clarity',
    feature_tutorial_desc:'Gone are the days of being confused over who said what in your audio content. With Taption, identifying voices in podcasts, interviews, or group discussions couldn’t be easier – all thanks to our speaker recognition software. Simply upload your transcript and let our AI speaker detection technology do the heavy lifting. <br><br>What exactly does our technology do? It pinpoints and tags each speaker’s contributions with ease. By using our automated speaker identification you guarantee perfect clarity in all your videos so that your audience never ends up confused over who is speaking or has a hard time following the conversation.  ',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"The advantages of clear speaker labeling",
    feature_extra_desc:"In discussions with multiple voices, knowing who's speaking is key - it shapes the listener's experience. With Taption, you get:<br><ul><li><strong>Transparent Dialogue: </strong>Listeners can easily follow the conversation since each voice is distinctly marked.</li><li><strong>Elevated Transcripts: </strong>Your content leaves a more professional impression that enhances your company’s image.</li><li><strong>Time Saved:</strong>Imagine the hours you can reclaim with an audio labeling tool that nails speaker identification on the first try.</li></ul><br>Experience the benefits of professional speaker identification with Taption’s advanced tools for yourself. Thanks to our multi-speaker transcript tagging and voice attribution solution you can be sure that your meeting transcription services and interview transcript labeling meet the highest standards of accuracy and detail."
}