module.exports = {
    convert_to_text_title:"발표자 트랜스크립트",
    convert_to_text_desc:"기존 녹취록에 화자 라벨을 자동으로 추가합니다",
    start_your_trial:"지금 사용해 보세요!",
    hero_img:"./10_English.webp",

    step_one:"미디어 파일 업로드",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"보유하고 있는 트랜스크립트와 일치하는 오디오 또는 비디오 파일을 선택합니다",
    
    step_two:"트랜스크립트 파일 업로드",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"선택 <span style=\"color:#3ccebd\"><strong>텍스트 파일 가져오기</strong></span> 에 대한 <strong>전사 방법</strong> 그리고 <span style=\"color:#3ccebd\"><strong>스피커 라벨링</strong></span> 에 대한 <strong>출력 형식 선택</strong>",

    step_three:"편집 및 내보내기",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"완료되면 편집 플랫폼으로 이동하여 화자 이름을 업데이트하거나 번역하세요. 준비가 되면 내보내기하세요! ",

    feature_tutorial_title:'선명도 향상을 위한 자동화된 스피커 라벨링',
    feature_tutorial_desc:'오디오 콘텐츠에서 누가 무슨 말을 했는지 혼란스러워하던 시대는 지났습니다. Taption을 사용하면 화자 인식 소프트웨어 덕분에 팟캐스트, 인터뷰 또는 그룹 토론에서 음성을 식별하는 것이 이보다 더 쉬울 수 없습니다. 녹취록을 업로드하기만 하면 AI 화자 인식 기술이 알아서 처리합니다. <br><br>기술이 정확히 어떤 역할을 하나요? 각 화자의 기여도를 쉽게 찾아내고 태그를 지정합니다. 자동 화자 식별 기능을 사용하면 모든 동영상에서 완벽한 선명도를 보장하여 청중이 누가 말하는지 혼란스러워하거나 대화를 따라가는 데 어려움을 겪지 않도록 할 수 있습니다. ',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"명확한 스피커 라벨링의 장점",
    feature_extra_desc:"여러 사람이 참여하는 토론에서는 누가 말하는지 아는 것이 핵심이며, 이는 듣는 사람의 경험을 형성합니다. Taption을 사용하면 다음과 같은 이점이 있습니다:<br><ul><li><strong>투명한 대화: </strong>각 음성이 뚜렷하게 표시되므로 청취자가 대화를 쉽게 따라갈 수 있습니다.</li><li><strong>향상된 트랜스크립트: </strong>콘텐츠는 보다 전문적인 인상을 남기며 회사 이미지를 향상시킵니다.</li><li><strong>시간 절약:</strong>첫 번째 시도에서 화자 식별이 가능한 오디오 라벨링 도구로 절약할 수 있는 시간을 상상해 보세요.</li></ul><br>Taption의 고급 도구로 전문 화자 식별의 이점을 직접 경험해 보세요. 다중 화자 트랜스크립트 태깅 및 음성 속성 솔루션 덕분에 회의 트랜스크립트 서비스 및 인터뷰 트랜스크립트 라벨링이 최고 수준의 정확성과 세부 사항을 충족할 수 있습니다."
}