module.exports = {
    convert_mp3_text: 'MP3 をテキストに変換',
    convert_mp3_desc:'Taption には、Web 上で最も正確な音声テキスト変換オンライン サービスがあります。当社の AI テクノロジーは、最も正確なサービスを提供します。 ',
    convert_mp3_now: 'すぐにテキストに変換',
    common_questions_title: 'よくある質問',
    convert_button_desc1: 'ˇ無料の 15 分間の動画アップロード',
    convert_button_desc2: '登録にクレジットカードは必要ありません',
    question1: '動画をテキストに変換する方法は?',
    question2: '動画に字幕を付ける方法は?',
    question2_desc: '動画に字幕を挿入できます',
    question3: '動画の翻訳方法は?',
    question4: '「AI 辞書」と「すべて置換」を使用して精度を上げるにはどうすればよいですか?',
    question5: '「字幕編集ビデオクリップ」機能の使い方は？」',
    home: 'ホームページに戻る',
    transcribe_now: '今すぐ変身',
    taption:'Taption - ビデオからテキストへの自動変換',
    title:'ビデオからテキストへの自動変換 | Taption',
    content_title:'AIが自動的にオーディオとビデオをテキストに変換し、字幕を翻訳し、多言語字幕をビデオに埋め込みます | Taption',
    content_description:'Taption はビデオを字幕に変換し、40 以上の言語をサポートします。強力な編集プラットフォームには、テキスト付きのビデオの編集、バイリンガル字幕ビデオのエクスポート、ビデオ内の文字の自動マーキング、字幕の翻訳などの機能が含まれています。 ',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com/jp',
    site_name:'Taption'
}