module.exports = {
'en':'英语',
'zh-TW':'中文(繁体)',
// 'zh-CHT':'中文(繁体)',
'zh':'中文(简体)',
'yue-Hant-HK': '粤语',
'yue-Hant-HK-TW': '粤语',
'ja':'日语',
'ko':'韩文',
'es':'西班牙语',
'de':'德语',
'fr':'法语',
'af':'南非荷兰语',
'sq':'阿尔巴尼亚语',
'am':'阿姆哈拉语',
'ar':'阿拉伯语',
'hy':'亚美尼亚语',
'az':'阿塞拜疆人',
'eu':'巴斯克语',
'be':'白俄罗斯语',
'bn':'孟加拉',
'bs':'波斯尼亚语',
'bg':'保加利亚语',
'ca':'加泰罗尼亚语',
'co':'科西嘉人',
'hr':'克罗地亚语',
'cs':'捷克',
'da':'丹麦语',
'nl':'荷兰语',
'fi':'芬兰语',
'el':'希腊语',
'gu':'古吉拉特语',
'he':'希伯来语',
'hi':'印地语',
'hu':'匈牙利语',
'id':'印尼文',
'ga':'爱尔兰',
'it':'意大利语',
'la':'拉丁',
'lv':'拉脱维亚语',
'lt':'立陶宛语',
'ms':'马来语',
'no':'挪威文',
'pl':'波兰语',
'pt':'葡萄牙语（巴西,葡萄牙）',
'ro':'罗马尼亚语',
'ru':'俄语',
'sr':'塞尔维亚文',
'sk':'斯洛伐克',
'sl':'斯洛文尼亚语',
'sv':'瑞典语',
'te':'泰卢固语',
'th':'泰国',
'tr':'土耳其语',
'uk':'乌克兰语',
'uz':'乌兹别克',
'vi':'越南',
'yi':'意第绪语',
'zu':'祖鲁语'
  }
  