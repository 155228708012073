module.exports = {
  'en': '영어',
  'zh-TW': '중국어(번체)',
  // 'zh-CHT': '중국어(번체)',
  'zh': '중국어(간체)',
  'yue-Hant-HK': '중국어(광둥어)',
  'yue-Hant-HK-TW': '중국어(광동어)',
  'ja': '일본어',
  'ko': '한국어',
  'es': '스페인어',
  'de': '독일어',
  'fr': '프랑스어',
  'af': '아프리칸스어',
  'sq': '알바니아어',
  'am': '암하라어',
  'ar': '아랍어',
  'hy': '아르메니아어',
  'az': '아제르바이잔어',
  'eu': '바스크어',
  'be': '벨로루시어',
  'bn': '벵골어',
  'bs': '보스니아어',
  'bg': '불가리아어',
  'ca': '카탈로니아어',
  'co': '코르시카',
  'hr': '크로아티아어',
  'cs': '체코어',
  'da': '덴마크어',
  'nl': '네덜란드어',
  'fi': '핀란드어',
  'el': '그리스어',
  'gu': '구자라트어',
  'he': '히브리어',
  'hi': '힌디어',
  'hu': '헝가리어',
  'id': '인도네시아어',
  'ga': '아일랜드어',
  'it': '이탈리아어',
  'la': '라틴어',
  'lv': '라트비아어',
  'lt': '리투아니아어',
  'ms': '말레이어',
  'no': '노르웨이어',
  'pl': '폴란드어',
  'pt': '포르투갈어(포르투갈, 브라질)',
  'ro': '루마니아어',
  'ru': '러시아어',
  'sr': '세르비아어',
  'sk': '슬로바키아어',
  'sl': '슬로베니아어',
  'sv': '스웨덴어',
  'te': '텔루구어',
  'th': '태국어',
  'tr': '터키어',
  'uk': '우크라이나어',
  'uz': '우즈벡어',
  'vi': '베트남어',
  'yi': '이디시어',
  'zu': '줄루어'
}