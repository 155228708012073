module.exports = {
    convert_to_text_title:"影音人物標記",
    convert_to_text_desc:"自動為影音標記人物",
    start_your_trial:"馬上試！",
    hero_img:"./04_Chinese.webp",

    step_one:"上傳影片或錄音檔",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"從電腦，Youtube, Google Drive或Zoom選取要標記人物的影音檔案。",
    
    step_two:"選擇「發聲者」來分段",
    step_two_img:"./speaker_step2_zh.webp",
    step_two_desc:"上傳後分段方式選擇「發聲者」後文字會自動根據說話人物分段並標記。",

    step_three:"完成",
    step_three_img:"./speaker_step3_zh.webp",
    step_three_desc:"轉成文字後後進入編輯平台進行調整後即可導出！",

    feature_tutorial_title:'自動人物標記教學',
    feature_tutorial_desc:'影片轉成文字後並自動標記人物可以讓影片內容及結構更明確。參考以下教學影片！',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/tr8qrM8mnNA?autoplay=1',
    //https://youtu.be/tr8qrM8mnNA

    feature_extra_title:"會議記錄的幾個原則",
    feature_extra_desc:"<ul><li>記錄重點在於掌握主題、點出整個會議的「議題」，記錄的重點並非談話內容，而是「與什麼有關的談話」。不過記錄的內容還是要以實錄性為重，切勿加入記錄者的觀點或傾向或刪改發言者的言論內容</li><li>設法透過會議記錄來「銜接會議」：會議記錄也能是帶領會議走向的指標，更能作為後續的提醒及依據如果能透過「先前的會議流程」與「日後的會議計畫」來讓與會者有所共識，就比較能避免失焦的混亂會議</li><li>當會議記錄能一鍵把錄音檔全數轉成文字的時候，以上就是撰寫一份精簡的會議報告該遵循的寫作原則</li></ul>"
}