module.exports = {
    convert_to_text_title:"スピーカーへのトランスクリプト",
    convert_to_text_desc:"既存のトランスクリプトにスピーカーラベルを自動的に追加",
    start_your_trial:"今すぐお試しください！",
    hero_img:"./10_English.webp",

    step_one:"メディアファイルをアップロードする",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"お持ちのトランスクリプトに一致するオーディオまたはビデオファイルを選択します",
    
    step_two:"トランスクリプトファイルをアップロードする",
    step_two_img:"./transcripts_subtitles_step02_en.webp",
    step_two_desc:"選択 <span style=\"color:#3ccebd\"><strong>テキストファイルをインポート</strong></span> 前 <strong>転写方法</strong> そして <span style=\"color:#3ccebd\"><strong>スピーカー・ラベリング</strong></span> 前 <strong>アウトプット・フォーマットを選択する</strong>",

    step_three:"編集とエクスポート",
    step_three_img:"./export_srt_step03_en.webp",
    step_three_desc:"完成したら、編集プラットフォームでスピーカーのネーミングを変更したり、翻訳したりします。準備ができたらエクスポートします！ ",

    feature_tutorial_title:'スピーカーの自動ラベリングによる明瞭度の向上',
    feature_tutorial_desc:'音声コンテンツで誰が何を言ったか混乱する時代はもう終わりました。 Taption を使用すると、話者認識ソフトウェアのおかげで、ポッドキャスト、インタビュー、またはグループ ディスカッション内の音声を識別することがこれまで以上に簡単になります。トランスクリプトをアップロードするだけで、AI 話者検出テクノロジーに面倒な作業を任せることができます。 <br><br>私たちのテクノロジーは具体的に何をするのですか? 各講演者の貢献を簡単に特定し、タグ付けします。自動話者識別を使用すると、すべてのビデオで完全な明瞭さが保証されるため、視聴者が誰が話しているのか混乱したり、会話についていくのに苦労したりすることがなくなります。 ',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3DNDiW8jiDU?si=ZWqZnGBTdjUmVU0_&autoplay=1',

    feature_extra_title:"クリアスピーカー・ラベリングの利点",
    feature_extra_desc:"複数の声が飛び交うディスカッションでは、誰が話しているのかを知ることが重要です。Taptionを使えば：<br><ul><li><strong>透明性のある対話 </strong>各音声が明瞭に区別されているので、リスナーは簡単に会話を追うことができる。</li><li><strong>高められたトランスクリプト： </strong>よりプロフェッショナルな印象を与え、企業イメージを向上させます。</li><li><strong>時間の節約：</strong>話者の識別を一発で行うオーディオラベリングツールがあれば、何時間もの時間を節約できます。</li></ul><br>Taptionの高度なツールを使って、プロフェッショナルな話者識別の利点をご自身で体験してください。Taptionの複数話者のテープ起こしタグ付けと音声帰属ソリューションにより、会議のテープ起こしサービスとインタビューのテープ起こしのラベリングが最高水準の精度と詳細を満たすことを確信できます。"
}