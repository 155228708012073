module.exports = {
    convert_mp3_text:'MP3转换成文字',
    convert_mp3_desc:'Taption拥有网路上最准确的语音转文字线上自动服务．我们的AI技术给你最精准的服务． ',
    convert_mp3_now:'马上转化成文字',
    common_questions_title: '常见问题',
    convert_button_desc1:'ˇ免费15影片上传分钟',
    convert_button_desc2:'注册无需信用卡',
    question1:'如何把影片转成文字?',
    question2:'如何为影片加字幕?',
    question2_desc:'字幕嵌入影片',
    question3:'如何翻译影片?',
    question4:'如何使用『AI辞典』和『全部替换』来增加精确性?',
    question5:'如何使用『字幕剪辑影片片段』功能?',
    home:'回首页',
    transcribe_now:'马上转换',
    taption:'Taption 音易 - 自动影音转文字',
    title:'自动影音转文字 | Taption 音易',
    content_title: 'AI自动帮你影音转文字，翻译字幕，多国语言字幕崁入影片 | Taption 音易',
    content_description:'Taption将影音转字幕，支援超过40种语言。强大编辑平台包括用文字剪辑影片，导出双语字幕影片，自动标记影片内人物，翻译字幕等功能！',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com/zh-CN',
    site_name:'Taption 音易'



}