module.exports = {
    convert_to_text_title_meta:"自動文字加上時間軸：自動TXT轉SRT字幕",
    convert_to_text_desc_meta:"上傳手邊的逐字稿，利用線上字幕產生器自動加上時間軸，快速完成TXT轉SRT。免費體驗，立即註冊開始使用！",
    convert_to_text_title:"自動TXT轉SRT字幕",
    convert_to_text_desc:"上傳手邊的逐字稿，利用字幕產生器自動加上時間軸並分段，快速完成TXT轉SRT",
    start_your_trial:"馬上試！",
    hero_img:"./09_Chinese.webp",

    step_one:"選擇逐字稿的影音檔",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"先選擇逐字稿對應的影音檔案，逐字稿本身和影片語言必需相同",
    
    step_two:"上傳逐字稿",
    step_two_img:"./transcripts_subtitles_step02_zh.webp",
    step_two_desc:"在視窗 <strong>文字生成方式</strong> 選 <span style=\"color:#3ccebd\">匯入文字檔案</span>，<strong>文字分段方式</strong> 選 <span style=\"color:#3ccebd\">字幕分段</span>",

    step_three:"編輯並導出",
    step_three_img:"./export_srt_step03_zh.webp",
    step_three_desc:"進入編輯平台字幕編輯後導出",

    feature_tutorial_title:'將手邊的文字轉成字幕功能',
    feature_tutorial_desc:'不論您擁有逐字稿、文章或其他文字內容，只需簡單上傳到Taption平台，就能快速運用字幕產生器，自動轉化為符合影片的字幕，完成txt轉srt。當你手邊已經有逐字稿，您無需手動輸入字幕，我們的字幕產生器有高度精確性和一致性，能省去對齊時間軸的麻煩工作。此外，您還可以輕鬆翻譯超過40種語言的字幕，滿足全球觀眾的多樣需求。',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title:"逐字稿轉字幕的整合應用",
    feature_extra_desc:"<ul><li><strong>影片字幕製作：</strong>當您已經擁有影片的文字內容，如劇本或對話，您可以將這些文字快速轉換為影片字幕。</li><li><strong>教育和培訓材料：</strong>如果您是教育者或培訓師，您可以將教學內容的文字轉換為字幕，以便學生更容易理解和記錄。特別適合網路課程或培訓課程。</li><li><strong>提高SEO：</strong>將影片內容轉化為文字字幕有助於搜索引擎索引，提高網站或影片的可見性。</li></ul>",

    feature_what_title:"常見問題與解答",
    feature_what_desc: "<ul><li><h2>上傳的TXT檔案除了自動加上時間軸以外，可以自動分段嗎？</h2><br>當然可以。我們提供三種自動分段的方式：<ul><li>自動字幕分段：利用字幕產生器的分段功能，根據影片內容自動判斷最佳分段位置。</li><li>根據不同人說話分段：適用於會議記錄或podcast等，可以按照不同人的發言進行分段。</li><li>依據TXT文件內的原始分段：根據提供的文字檔案中已存在的分段進行分段。</li></ul><br><br></li>   <li><h2>我沒有TXT文字檔，系統能自動幫我產生逐字稿或字幕嗎？</h2><br>當然可以。當您上傳影片檔案時，請選擇<strong>文字生成方式</strong>中的<strong>AI自動生成</strong>選項。我們的AI字幕產生器會自動將您的影片內容轉換成字幕。更多相關資訊，歡迎訪問<a href=\"https://www.taption.com/add-subtitles/zh\">自動影片上字幕</a>或<a href=\"https://www.taption.com/convert-to-text/zh\">影音轉為逐字稿</a>查詢。<br><br></li>  <li><h2>將TXT文字檔加上時間軸後，是否可以將其翻譯成其他語言？</h2><br>當然可以。將TXT文字檔轉成中文字幕或逐字稿後，並完成時間軸的分段，您可以在編輯平台的右上方選擇將其翻譯成40多種語言，例如中翻英 、中翻日、中翻越等。更多翻譯相關資訊，請參考<a href=\"https://www.taption.com/translate/zh\">自動影片翻譯</a>。</li></ul>"
}