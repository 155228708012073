module.exports = {
  'en-US': '영어(미국)',
  'en-GB': '영어(영국)',
  'en-AU': '영어(호주)',
  'zh-TW': '중국어(대만)',
  'zh': '중국어(중국)',
  //'zh-CHT': '중국어 + 대만어(대만)',
  'yue-Hant-HK-TW-zh': '광동어(중국어 번체 출력)',
  'yue-Hant-HK-zh': '광둥어(중국어 간체 출력)',
  'yue-Hant-HK-TW': '광동어(번체)',
  'yue-Hant-HK': '광동어(간체)',
  'es-ES': '스페인어(스페인)',
  'fr-FR': '프랑스어(프랑스)',
  'de-DE': '독일어(독일)',
  'it-IT': '이탈리아어(이탈리아)',
  'pt-PT_pt-BR': '포르투갈어(포르투갈,브라질)',
  'nl-BE': '네덜란드어(벨기에)',
  'hi-IN': '힌디어(인도)',
  'ja-JP': '일본어(일본)',
  'ko-KR': '한국어(대한민국)',
  'vi-VN': '베트남어(베트남)',
 
  'ar-EG': '아랍어(이집트)',
  'hy-AM': '아르메니아어(아르메니아)',
  'bs-BA': '보스니아어(BiH)',
  'bg-BG': '불가리아어(불가리아)',
  'my-MM': '버마어(미얀마)',
  'ca-ES': '카탈로니아어(스페인)',
  'hr-HR': '크로아티아어(크로아티아)',
  'cs-CZ': '체코(체코 공화국)',
  'da-DK': '덴마크어(덴마크)',
  //'en-IN': '영어(인도)',
  //'fil-PH': '필리핀어(필리핀)',
  'tl-PH': '필리핀어(필리핀)',
  'fi-FI': '핀란드어(핀란드)',
  'gl-ES': '갈리시아어(스페인)',
  'el-GR': '그리스어(그리스)',
  'gu-IN': '구자라트어(인도)',
  'iw-IL': '히브리어(이스라엘)',
  'hu-HU': '헝가리어(헝가리)',
  'id-ID': '인도네시아어(인도네시아)',
  'lv-LV': '라트비아어(라트비아)',
  'lt-LT': '리투아니아어(리투아니아)',
  'mk-MK': '마케도니아어(북마케도니아)',
  'ms-MY': '말레이어(말레이시아)',
  'no-NO': '노르웨이어(노르웨이)',
  'pl-PL': '폴란드어(폴란드)',
  'ro-RO': '루마니아어(루마니아)',
  'ru-RU': '러시아어(러시아)',
  'sk-SK': '슬로바키아어(슬로바키아)',
  'sl-SI': '슬로베니아어(슬로베니아)',
  'sv-SE': '스웨덴어(스웨덴)',
  'ta-IN': '타밀어(인도)',
  'te-IN': '텔루구어(인도)',
  'th-TH': '태국어(태국)',
  'tr-TR': '터키어(터키)',
  'uk-UA': '우크라이나어(우크라이나)',
  'uz-UZ': '우즈벡어(우즈베키스탄)',
  // 'zu-ZA': '줄루어(남아프리카 공화국)'
}