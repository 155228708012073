module.exports = {
    convert_to_text_title_meta:"자동 시간 축 추가: TXT를 SRT 자막으로 자동 변환",
    convert_to_text_desc_meta:"기존 대본을 업로드하고 온라인 자막 생성기로 시간 축을 자동 추가하여 TXT를 SRT로 빠르게 변환하세요. 무료로 체험하고 지금 바로 등록하세요!",
    convert_to_text_title:"TXT를 SRT 자막으로 자동 변환",
    convert_to_text_desc:"기존 대본을 업로드하고 자막 생성기로 시간 축을 자동 추가하고 분할하여 TXT를 SRT로 빠르게 변환",
    start_your_trial:"지금 시작하기!",
    hero_img:"./09_Chinese.webp",

    step_one:"대본의 오디오/비디오 파일 선택",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"먼저 대본에 해당하는 오디오/비디오 파일을 선택하세요. 대본과 영상의 언어가 동일해야 합니다",
    
    step_two:"대본 업로드",
    step_two_img:"./transcripts_subtitles_step02_zh.webp",
    step_two_desc:"<strong>텍스트 생성 방법</strong> 창에서 <span style=\"color:#3ccebd\">텍스트 파일 가져오기</span>를 선택하고, <strong>텍스트 분할 방법</strong>에서 <span style=\"color:#3ccebd\">자막 분할</span>을 선택하세요",

    step_three:"편집 및 내보내기",
    step_three_img:"./export_srt_step03_zh.webp",
    step_three_desc:"편집 플랫폼에서 자막을 편집한 후 내보내기",

    feature_tutorial_title:'텍스트를 자막으로 변환하는 기능',
    feature_tutorial_desc:'대본, 기사 또는 기타 텍스트 내용을 Taption 플랫폼에 업로드하면 자막 생성기를 사용하여 비디오에 맞는 자막으로 빠르게 변환할 수 있습니다. 이미 대본이 있다면 수동으로 자막을 입력할 필요 없이 자막 생성기가 정확하고 일관되게 시간 축을 맞춰줍니다. 또한 40개 이상의 언어로 자막을 쉽게 번역하여 전 세계 시청자의 다양한 요구를 충족할 수 있습니다.',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title:"대본을 자막으로 변환하는 통합 응용",
    feature_extra_desc:"<ul><li><strong>비디오 자막 제작:</strong> 비디오의 대본이나 대화 내용이 있다면 이를 빠르게 비디오 자막으로 변환할 수 있습니다.</li><li><strong>교육 및 훈련 자료:</strong> 교육자나 트레이너라면 교육 내용을 자막으로 변환하여 학생들이 더 쉽게 이해하고 기록할 수 있게 할 수 있습니다. 온라인 강좌나 훈련 과정에 특히 적합합니다.</li><li><strong>SEO 향상:</strong> 비디오 내용을 텍스트 자막으로 변환하면 검색 엔진 색인에 도움이 되어 웹사이트나 비디오의 가시성을 높일 수 있습니다.</li></ul>",

    feature_what_title:"자주 묻는 질문과 답변",
    feature_what_desc: "<ul><li><h2>업로드한 TXT 파일에 시간 축을 자동으로 추가하는 것 외에 자동으로 분할할 수 있나요?</h2><br>네, 가능합니다. 세 가지 자동 분할 방식을 제공합니다:<ul><li>자동 자막 분할: 자막 생성기의 분할 기능을 사용하여 비디오 내용에 따라 최적의 분할 위치를 자동으로 판단합니다.</li><li>다른 사람의 말에 따른 분할: 회의 기록이나 팟캐스트 등에 적합하며, 다른 사람의 발언에 따라 분할합니다.</li><li>TXT 파일의 원래 분할에 따른 분할: 제공된 텍스트 파일에 이미 존재하는 분할에 따라 분할합니다.</li></ul><br><br></li>   <li><h2>TXT 텍스트 파일이 없는데 시스템이 자동으로 대본이나 자막을 생성할 수 있나요?</h2><br>네, 가능합니다. 비디오 파일을 업로드할 때 <strong>텍스트 생성 방법</strong>에서 <strong>AI 자동 생성</strong> 옵션을 선택하세요. AI 자막 생성기가 자동으로 비디오 내용을 자막으로 변환합니다. 자세한 정보는 <a href=\"https://www.taption.com/add-subtitles/kr\">자동 비디오 자막 추가</a> 또는 <a href=\"https://www.taption.com/convert-to-text/kr\">오디오/비디오를 대본으로 변환</a>을 참조하세요.<br><br></li>  <li><h2>TXT 텍스트 파일에 시간 축을 추가한 후 다른 언어로 번역할 수 있나요?</h2><br>네, 가능합니다. TXT 텍스트 파일을 한국어 자막이나 대본으로 변환하고 시간 축 분할을 완료한 후, 편집 플랫폼의 오른쪽 상단에서 40개 이상의 언어로 번역할 수 있습니다. 예를 들어 한국어에서 영어, 일본어, 베트남어 등으로 번역할 수 있습니다. 번역에 대한 자세한 정보는 <a href=\"https://www.taption.com/translate/kr\">자동 비디오 번역</a>을 참조하세요.</li></ul>"
}