module.exports = {
    convert_mp3_text:'MP3轉換成文字',
    convert_mp3_desc:'Taption擁有網路上最準確的語音轉文字線上自動服務．我們的AI技術給你最精準的服務．',
    convert_mp3_now:'馬上轉化成文字',
    common_questions_title: '常見問題',
    convert_button_desc1:'ˇ免費15影片上傳分鐘',
    convert_button_desc2:'註冊無需信用卡',
    question1:'如何把影片轉成文字?',
    question2:'如何為影片加字幕?',
    question2_desc:'字幕嵌入影片',
    question3:'如何翻譯影片?',
    question4:'如何使用『AI辭典』和『全部替換』來增加準確性?',
    question5:'如何使用『字幕剪輯影片片段』功能?',
    home:'回首頁',
    transcribe_now:'馬上轉換',
    taption:'Taption - 自動影音轉文字',
    title:'自動影音轉文字 | Taption 音易',
    content_title:'AI自動幫你影音轉文字，翻譯字幕，多國語言字幕崁入影片 | Taption 音易',
    content_description:'Taption將影音轉字幕，支援超過40種語言。強大編輯平台包括用文字剪輯影片，導出雙語字幕影片，自動標記影片內人物，翻譯字幕等功能！',
    cover_image_url:'https://www.taption.com/taption-cover-en.jpg',
    site_url:'https://www.taption.com/zh',
    site_name:'Taption 音易'

}