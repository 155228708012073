module.exports = {
  enjoy_your_free_trial: '여러분의 첫 자동 받아쓰기, 무료로 해드립니다!',
  dialog_title: '이용시간을 추가',
  subscription_title: '유료플랜을 구독',
  pay: '결제',
  error_unauthenticate: '결제 수단을 승인할 수 없습니다. 다른 카드로 시도해 주세요.',
  error_payment_creation: '결제를 완료할 수 없습니다. 고객지원팀에 문의하세요.',
  error_general: '서버와의 연결을 완료할 수 없습니다. 새로고침하고 다시 시도하십시오.',
  error_timeout: '연결 시간이 초과되었습니다. 새로고침하고 다시 시도하세요.',

  expired_card:'만료된 카드입니다. 다른 카드를 사용하십시오.',
  do_not_honor:'카드 결제가 승인되지 않았습니다. 자세한 내용은 카드 발급사에 문의하세요.',
  insufficient_funds:'카드 잔액이 부족합니다.',
  invalid_info: '제공된 카드 정보가 유효하지 않습니다. 확인하고 다시 시도하십시오.',

  success: '결제 완료',
  success_description: '계정에 <br/> <b class="number-of-minutes">{0} 분</b>이 적립되었습니다',
  success_description_bonus: '보너스 이용시간: <b class ="extra-minutes"> {1}분</b> <br/>계정에 <br/> <b class="number-of-minutes">{0}분</b>이 추가 적립되었습니다',
  success_subscription_description: '프리미엄 플랜이 시작되었습니다',
  success_corp_subscription_description: '엔터프라이즈 플랜이 시작되었습니다',
  cancel_subscription_confirmation: '구독을 정말 취소하시겠습니까?',
  success_subscription_bonus: '보너스 이용시간: <b class ="extra-minutes"> {0}분</b>',

  cancel_subscription_desc:' 남은 이용시간은 구독 취소시에만 만료됩니다<br>구독 취소 후에는 발화자 라벨 중 첫 <span style="color:rgb(246, 102, 101)!important">1</span> 라벨만 사용할 수 있습니다',
  cancel_subscription_confirm:'상기 이용시간 잔액을 포기할 것을 확인합니다',
  reactivate_subscription_confirmation: '구독을 재개하시겠습니까?',
  cacnel_subscription_description: '현재 구독기간이 만료될 때까지 프리미엄 플랜이 유지됩니다.',
  cacnel_subscription: '프리미엄 플랜 구독이 취소되었습니다',
  cancel_corp_subscription: '엔터프라이즈 플랜 구독이 취소되었습니다',
  reactive_subscription: '유료플랜이 재활성화되었습니다!',
  active_until:'만료일: ',
  success_translation_description: '번역이 준비되었습니다!',
  yes: '예',
  no: '아니오',
  continue: '계속',
  save: '저장',
  cancel: '취소',
  processing_payment: '결제 처리중',
  error_edit: '카드정보 변경에 실패했습니다. 고객지원팀에 문의하세요',
  delete_ok: '삭제',
  delete_cancel: '취소',
  billing_address: '청구서 주소',
  how_many_hours: '추가 이용시간(시간)',
  credit_card: '신용카드',
  summary: '요약 (USD)',
  save_payment: '이 카드를 다음에도 사용',
  processing_payment_description: '이 작업에는 수분간 소요될 수 있습니다...',
  credit_card_manage:'귀하의 신용 카드는 Stripe, Inc에서 안전하게 처리됩니다.',
  credit_card_secured:'거래는 안전하고 암호화됩니다.',
  understand_billing:'이 결제를 승인합니다',
  processing_saving:'카드 정보 업데이트 중',
  // field
  full_name: '이름',
  name: '이름',
  address: '주소',
  city: '시',
  state: '도',
  zip: '우편번호',
  // validation
  max_length_field: '{0}는 {1}자 이상 입력해야 합니다',
  required_field: '{0}는 필수항목입니다',
  delete_title: '이 카드를 삭제하시겠습니까?',

  title_basic: '스탠다드',
  title_pay_as_you_go: '사용한 만큼 결제 플랜',
  title_premium: '프리미엄 플랜',
  title_custom: '커스텀 플랜',
  title_corp: '엔터프라이즈 플랜',
  title_subscription:'구독',

  basic_feature5: '잔여이용시간 <span style="color: #00d1b2;">60분</span>',
  //basic_feature1: '자동 받아쓰기',
  //basic_feature2: '39+개 언어 지원',
  //basic_feature3: '텍스트 편집기',
  //basic_feature4: '50+개 언어로 번역',
  // basic_feature7: 'AI 맞춤사전',
  // basic_feature6: '상업적 이용 가능',
  // basic_feature4_tooltip: '',
  //basic_feature4: 'SEO를 향상시키는 동영상 재생기',

  premium_feature1: '{0} <span style="color: #00d1b2;">분</span> every month.<br><span class="price-notes">(이월된 이용시간은 구독 취소시까지 사용가능)</span>',
  //premium_feature1_tooltip: '사용되지 않고 남은 월간 이용시간은 다음달로 이월되며 구독 취소시까지 사용 가능합니다. <br>월간 이용시간은 추가로 구매하신 이용시간에 우선하여 차감됩니다. <br>추가로 구매하신 이용시간은 구독 취소시에도 만료되지 않고 계속해서 사용가능합니다.',
  premium_feature2: '이용시간 시간당 구매시 25% 할인',
  // premium_feature3: '자체자막 삽입 동영상 추출 무제한 무료',
  premium_feature3_tooltip: '문서당 $2',
  // premium_feature4: '받아쓰기 공유 및 협업',
  // premium_feature5: '다크 모드',
  // premium_feature6: '텍스트 편집으로 동영상 편집',

  buy: '이용시간(분) 구매',
  activate: '활성화',
  subscribe: '구독',
  contact_us: '문의하기',

  custom_feature1: '사업 제휴',
  custom_feature2: '대량 구독',
  custom_feature3: '일괄청구',
  custom_feature4: '피드백',

  month: '월',
  per_hour: '1시간당',
  per_month: '1개월당',

  how_get_free:'무료 이용시간을 받으려면 어떻게 하나요?',
  payment_questions:'결제 관련 문의사항이 있으신가요?',

  translate_remaining:'{0} 남았습니다.',
  translate_deduct:'받아쓰기를 번역하시면 {0}만큼 차감됩니다.',
  transcribe_deduct:'받아쓰기를 번역하시면 {0}만큼 차감됩니다.',
  transcribe_warning:'받아쓰기 전에 이용시간 잔액이 충분히 남아있는지 확인해주세요. 작업이 실패할 수 있습니다.',
  mp4_deduct:'받아쓰기를 MP4로 추출하시면 {0}만큼 차감됩니다.',
  cvc:'CVC란 무엇인가요?',
  confirm_translate:'번역을 계속합니다',
  recurring_description:'＊ 매월 결제',
  recurring_description2:'＊ 「계정」 메뉴에서 언제든지 취소가능',
  coupon_header:'프로모션 코드',
  coupon_title:'프로모션 코드를 입력하세요:',
  coupon_submit:'적용',
  processing_coupon: '쿠폰 코드를 적용하는 중',
  coupon_empty: '쿠폰 코드를 입력하세요',
  coupon_expire: '만료된 쿠폰입니다',
  coupon_notexist: '유효하지 않은 쿠폰입니다',
  coupon_firsttimeonly: '신규 유저만 사용할 수 있는 쿠폰입니다',
  member_slot_desc:'이 계정에서 작성된 받아쓰기에 추가, 업로드, 편집 및 내보내기 권한을 가진 <br>멤버를 초대할 수 있는 최대 멤버 슬롯 수.',
  credit_edit_title:'신용카드 정보',

  payment_confirm_title:'결제 확인',
  payment_confirm_desc:'<span style="color:#2fc59f;font-weight:bold;">U.S. 달러</span>로 {0}만큼 결제되었습니다.',
  upgrade_contact_us:'대기업 솔루션 및 제휴 프로그램, 사업상 문의 등은 <span style="color:#2fc59f">help@taption.com</span>로 문의하세요'
}
