module.exports = {
    convert_to_text_title_meta:"線上翻譯軟體：自動影片翻譯",
    convert_to_text_desc_meta:"線上自動翻譯將MP4影片、MP3錄音或Youtube影片轉換為文字，再翻譯成超過40種語言，並支援將雙語字幕嵌入影片後導出。免費體驗，立即註冊開始使用！",
    convert_to_text_title:"自動影片翻譯",
    convert_to_text_desc:"線上自動翻譯將MP4影片、MP3錄音、Youtube影片轉為文字後翻譯成超過40多種語言，影片上字幕神器！",
    start_your_trial:"馬上試！",
    hero_img:"./03_Chinese.webp",

    step_one:"將影音轉為文字",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"翻譯前先將影片轉為文字，上傳時選擇的語言要和影片本身相同。這個過程涉及語音轉文字技術,可以將各種格式如MP4、MOV等影片轉換成文字。",
    
    step_two:"選擇翻譯語言",
    step_two_img:"./translate_step2_zh.webp",
    step_two_desc:"校正文字後可從編輯平台右上方進行翻譯。在翻譯前對原文進行校正可達到更好的翻譯結果。支援40多種語言翻譯,包括英文翻譯、中翻英、日文翻譯、韓文翻譯、印尼翻譯、越南翻譯等。",

    step_three:"編輯後導出",
    step_three_img:"./translate_step3_zh.webp",
    step_three_desc:"翻譯後會有對照平台進行調整後即可導出！支援多種格式如MP4影片檔、SRT字幕檔、TXT逐字稿等。",

    feature_tutorial_title:'翻譯影片教學',
    feature_tutorial_desc:'<p>Taption 的影片翻譯軟體專為解決傳統字幕翻譯過程中的常見問題而設計。我們的技術克服了以下三大挑戰，保證翻譯質量和觀看體驗：</p><ul><li><strong>時間同步：</strong>精確校正，確保翻譯後的字幕與影片時間完美同步。</li><li><strong>內容流暢：</strong>改善字幕連貫性，提供順暢的觀看體驗。</li><li><strong>意義準確：</strong>避免直譯導致的意義偏差，傳達正確信息。</li></ul><p>支持超過40種語言，包括英文、日文、韓文、越南文等，Taption 讓您能夠輕鬆創建和導出雙語字幕，全方位滿足多語言影片翻譯需求。透過我們的線上平台，無需下載任何軟件，即可開始使用此功能。無論您是影片製作人、教育工作者還是國際會議的組織者，Taption 的影片翻譯軟體都是您最佳的選擇，與其說是線上翻譯軟體，使用過的用戶會稱其為線上翻譯神器！</p>無論是將MP4或是MP3進行翻譯，我們的編輯平台都讓整個流程更順暢和省時。如果想要雙語字幕可參考這篇<a class="link" href="https://www.taption.com/add-subtitles/zh">雙語字幕的教學</a>。快來看以下自動翻譯的教學影片：',
    feature_tutorial_poster_url:'./translate_tutorial_poster.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/3KbT6st8nYU?autoplay=1',

    feature_extra_title:"常見問題",
    feature_extra_desc:"<ul><li><strong>可以翻譯哪些類型的影片格式？</strong><p>Taption提供便捷的自動轉檔功能支援多種影片和音頻格式，包括 mov、avi、wmv、flv、mpeg、ogg、mp4、m4a、wav 以及 mp3，滿足不同需求。我們還直接支援Webex、Zoom會議、Google Drive 影音檔案，以及YouTube 影片的下載和轉檔後的翻譯功能。</p></li><li><strong>影片翻譯需要多長時間？</strong><p>在Taption上，影片翻譯的時間通常很快，一般情況下翻譯過程會在1分鐘以內完成。具體時間可能會根據影片本身的長度和複雜度有所不同。</p></li><li><strong>影片翻譯的收費標準是怎樣的？</strong><p>對於 Taption 的影片翻譯和字幕生成，其獨特的計費方式是按照影片的總長度的一半分鐘數扣除。例如，如果一個 YouTube 影片的長度是 20 分鐘，則從用戶的賬戶中扣除 10 分鐘的分鐘數。這種計費方式適用於各類影片翻譯包括長用的：英翻中、日文翻譯、韓文翻譯、印尼翻譯、越南語翻譯等服務，旨在為用戶提供成本效益高的線上翻譯服務。</p></li></ul>"

    //feature_extra_desc:"影片內容翻譯功能帶來的價值：<ul><li>增加影片受眾，能讓你的影片使不同語言的觀眾能夠更方便欣賞、談論和分享影片，對Youtuber來說，更是流量密碼之一</li><li>優質的翻譯影片非常適合作為學習語言的教材，上了雙語字幕的影片更能吸引想用影片來學習語言的觀眾</li><li>將影片內容翻譯成外語字幕，對於專業譯者而言可以增加工作效率，對小團體或一人工作室的影片製作還能省去請翻譯的成本</li><li>商業宣傳影片之中，往往需要與外商合作，有一支翻譯影片能夠迅速讓客戶瞭解業務內容，就能事半功倍</li></ul>"
}