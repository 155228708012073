module.exports = {
    convert_to_text_title:"逐字稿人物标记",
    convert_to_text_desc:"上传手边的逐字稿，自动标记人物",
    start_your_trial:"马上试！",
    hero_img:"./10_Chinese.webp",

    step_one:"选择逐字稿的影音档",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"先选择逐字稿对应的影音档案，文字和影片语言需相同",
    
    step_two:"上传逐字稿",
    step_two_img:"./transcripts_subtitles_step02_zh.webp",
    step_two_desc:"在视窗<strong>文字生成方式</strong> 选<span style=\"color:#3ccebd\">汇入文字档案</span>，<strong>文字分段方式</strong> 选<span style=\"color:#3ccebd\">标记人物并分段</span>",

    step_three:"编辑并导出",
    step_three_img:"./export_srt_step03_zh.webp",
    step_three_desc:"进入编辑平台导出",

    feature_tutorial_title:'将已有的逐字稿标记上人物',
    feature_tutorial_desc:'Taption不仅可以将文字转为字幕，还能自动标记不同人物，使您的内容更具清晰度。这一功能特别适用于多人对话或访谈，它能自动标记每位参与者的发言。观众可以更容易区分每个人的发言，提高内容的理解度和吸引力。最重要的是，这一功能节省了大量手动标记不同发言者的时间，让您能够立即将文字或逐字稿转化为包含人物标记的字幕，充分节省您的时间和精力。 ',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',

    feature_extra_title:"人物标记功能的多元运用",
    feature_extra_desc:"<ul><li><strong>Podcast 制作：</strong>当您拥有podcast 的逐字稿或录音文字内容时，使用这个功能可以自动标记不同的说话者，例如主持人和来宾。 </li><li><strong>访谈稿整理：</strong>如果您在做访谈或对话内容整理时，有多个参与者的话，自动标记不同说话者可以使整理工作更加高效。您可以轻松识别每个参与者的发言，以便在后续整理和分析中更好地理解内容。</li><li><strong>研究和分析：</strong>学者和研究人员可以使用这一功能来分析多人对话内容。透过自动标记说话者，他们可以更轻松地跟踪每个参与者的言论，进行深入的内容分析和研究。</li><li><strong>多人演讲：</strong >在会议、研讨会或讲座等场合，多人演讲时，将文字标记不同的发言者可以帮助听众更好地理解讲者之间的转换，提高听众对内容的理解和注意力。</li ><li><strong>媒体报导：</strong>新闻机构可以使用这个功能来整理记者的采访内容或多人对话，以确保报导的清晰性和精确性。</li></ul>"
}