module.exports = {
    convert_to_text_title_meta:"自动文字加上时间轴：自动TXT转SRT字幕",
    convert_to_text_desc_meta:"上传手边的逐字稿，利用线上字幕产生器自动加上时间轴，快速完成TXT转SRT。免费体验，立即注册开始使用！",
    convert_to_text_title:"自动TXT转SRT字幕",
    convert_to_text_desc:"上传手边的逐字稿，利用字幕产生器自动加上时间轴并分段，快速完成TXT转SRT",
    start_your_trial:"马上试！",
    hero_img:"./09_Chinese.webp",
   
    step_one:"选择逐字稿的影音档",
    step_one_img:"./select_source_zh.webp",
    step_one_desc:"先选择逐字稿对应的影音档案，逐字稿本身和影片语言必需相同",
   
    step_two:"上传逐字稿",
    step_two_img:"./transcripts_subtitles_step02_zh.webp",
    step_two_desc:"在视窗<strong>文字生成方式</strong> 选<span style=\"color:#3ccebd\">汇入文字档案</span>，<strong>文字分段方式</strong> 选<span style=\"color:#3ccebd\">字幕分段</span>",
   
    step_three:"编辑并导出",
    step_three_img:"./export_srt_step03_zh.webp",
    step_three_desc:"进入编辑平台字幕编辑后导出",
   
    feature_tutorial_title:'将手边的文字转成字幕功能',
    feature_tutorial_desc:'不论您拥有逐字稿、文章或其他文字内容，只需简单上传到Taption平台，就能快速运用字幕产生器，自动转化为符合影片的字幕，完成txt转srt。当你手边已经有逐字稿，您无需手动输入字幕，我们的字幕产生器有高度精确性和一致性，能省去对齐时间轴的麻烦工作。此外，您还可以轻松翻译超过40种语言的字幕，满足全球观众的多样需求。 ',
    feature_tutorial_poster_url:'./transcripts_to_subtitles_thumbnail_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/cMc71uumga4?si=Asfp-N2yjNahp_M5&autoplay=1',
   
    feature_extra_title:"逐字稿转字幕的整合应用",
    feature_extra_desc:"<ul><li><strong>影片字幕制作：</strong>当您已经拥有影片的文字内容，如剧本或对话，您可以将这些文字快速转换为影片字幕。</li>< li><strong>教育和培训材料：</strong>如果您是教育者或培训师，您可以将教学内容的文字转换为字幕，以便学生更容易理解和记录。特别适合网路课程或培训课程。</li><li><strong>提高SEO：</strong>将影片内容转化为文字字幕有助于搜索引擎索引，提高网站或影片的可见性。</li></ul>",
   
    feature_what_title:"常见问题与解答",
    feature_what_desc: "<ul><li><h2>上传的TXT档案除了自动加上时间轴以外，可以自动分段吗？</h2><br>当然可以。我们提供三种自动分段的方式：< ul><li>自动字幕分段：利用字幕产生器的分段功能，根据影片内容自动判断最佳分段位置。</li><li>根据不同人说话分段：适用于会议记录或podcast等，可以按照不同人的发言进行分段。</li><li>依据TXT文件内的原始分段：根据提供的文字档案中已存在的分段进行分段。</li></ul ><br><br></li>   <li><h2>我没有TXT文字档，系统能自动帮我产生逐字稿或字幕吗？</h2><br>当然可以。当您上传影片档案时，请选择<strong>文字生成方式</strong>中的<strong>AI自动生成</strong>选项。我们的AI字幕产生器会自动将您的影片内容转换成字幕。更多相关资讯，欢迎访问<a href=\"https://www.taption.com/add-subtitles/zh\">自动影片上字幕</a>或<a href=\"https://www.taption. com/convert-to-text/zh\">影音转为逐字稿</a>查询。<br><br></li>  <li><h2>将TXT文字档加上时间轴后，是否可以将其翻译成其他语言？</h2><br>当然可以。将TXT文字档转成中文字幕或逐字稿后，并完成时间轴的分段，您可以在编辑平台的右上方选择将其翻译成40多种语言，例如中翻英、中翻日、中翻越等。更多翻译相关资讯，请参考<a href=\"https://www.taption.com/translate/zh\" >自动影片翻译</a>。</li></ul>"
   }