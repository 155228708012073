import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
//import '@mdi/font/css/materialdesignicons.css' 

// import './material-design-icons-iconfont/dist/material-design-icons.css'
Vue.use(Vuetify)

const opts = {
    iconfont: 'mdiSvg',
    theme: {
        themes: {
            light: {
                success: '#2fc59f',
                primary: '#0062ff',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#ea1539',
                info: '#2196F3',
                warning: '#FFB400'
            }
        }
    }
}

export default new Vuetify(opts)