module.exports = {
    convert_to_text_title_meta:"Convert Video to Text - Online AI Transcription Software",
    convert_to_text_desc_meta:"Automatically convert MP4 videos, YouTube videos, and MP3 audio to text online. Supports over 40 languages, provides multilingual subtitles, and features automatic formatting and speaker labeling.Sign up for a free trial today!",
    convert_to_text_title:"Convert video to text",
    convert_to_text_desc:"Automatically convert your MP4 video or MP3 audio to text.Support multilingual subtitles, auto formatting and speaker labeling.",
    start_your_trial:"Start your free trial!",
    hero_img:"./01_English.webp",

    three_step_title:"Transcribing steps",
    step_one:"Upload file",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"Select from device, Youtube, Google Drive or Zoom to convert to text.",
    
    step_two:"Choose method",
    step_two_img:"./select_split_en.webp",
    step_two_desc:"Choose your transcribing method from the following: 1) Automated transcription 2) Import text file 3) Manual, then choose Segment each sections for \"article\".",

    step_three:"Export",
    step_three_img:"./select_export_en.webp",
    step_three_desc:"After editing in the platform, export(srt,txt,pdf,mp4) by click on the export button in the top right.",

    feature_tutorial_title:'Effortless video-to-text conversion',
    //feature_tutorial_desc:'It has never been easier to convert your multimedia files – this is thanks to Taption\'s feature-rich editing platform. With our service, you can choose between converting mp4 to text or mp3 to text. Our transcription services offer you flexibility and accuracy: you get to choose what works best for you. If you are interested in translation, speaker labeling, or bilingual subtitles, then our platform handles it all with ease.<br><br>To master the video-to-text conversion process, take a moment to watch our tutorial below.',
    feature_tutorial_desc:'Taption provides four distinct methods to add subtitles to your video: <span class="bullet-point">•</span>Auto Transcribe <span class="bullet-point">•</span>SRT Import <span class="bullet-point">•</span>TXT Import <span class="bullet-point">•</span>Manual Entry<br><br>Our editing platform serves as an intuitive tool for audio to text, speech to text, and video to text conversions, helping to streamline your transcription tasks. For those of you reaching out to global audiences, we also offer support for multilingual subtitles. Be sure to watch our tutorial, where we explain these transcription steps in a simple way, enhancing your understanding of how to efficiently manage your video to text needs.',
    // feature_tutorial_poster_url:'./convert-to-text-tutorial-poster-en.webp',
    // feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',
    feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/KvyaH-QU9is?autoplay=1',

    feature_extra_title:"The many benefits of video-to-text conversion",
    feature_extra_desc:"Video-to-text conversion, which includes audio to text and speech to text processes, extends beyond the basic function of generating subtitles. These services provide a comprehensive suite of benefits that can significantly augment your content strategy through transcription. Consider the following key advantages of utilizing video to text, audio to text, and speech to text conversions in your operations: <ul><li><strong>Searchable Media:</strong> Your media files will be fully searchable, and this will boost your content discoverability. </li><li><strong>Efficient Reviewing and Content Modification:</strong> You are speeding up your review and editing processes without having to sacrifice quality. </li><li><strong>Easy Translation:</strong> You get to tackle multiple languages effortlessly and expand your global reach this way. </li><li><strong>SEO Optimization:</strong> You are making your media searchable, which also means that you're boosting your site's SEO performance. </li><li><strong>ADA Compliance:</strong> Your content will be accessible to all and will meet ADA compliance standards. </li></ul>",

    feature_why_image: "./translate_step1_en.webp",
    feature_what_title:'5 Steps to convert audio to text',
    feature_what_desc: "<ul>\n  <li>Register a new account or log in with Gmail: Go to the <a href=\"https://app.taption.com/login\">Taption login page</a>.</li>\n  <li>Click the \"Upload\" button and select the media source, such as uploading from your computer, YouTube, Google Drive, Zoom, or pasting a media link.</li>\n  <li>Select the \"Language\" and \"Segmentation Method\" needed for transcription.</li>\n  <li>After uploading, the file will automatically be converted to text, and you can proceed to the editing platform for further editing.</li>\n  <li>On the editing platform, you can translate, use AI analysis, and finally export the document or embed the subtitles into the video file.</li>\n</ul>"
}