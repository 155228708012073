module.exports = {
  'zh': '中文 (输出简体)',
  'zh-TW': '中文 (输出繁体)',
  'yue-Hant-HK-TW-zh': '粤语  (输出中文繁体)',
  'yue-Hant-HK-zh': '粤语  (输出中文简体)',
  'yue-Hant-HK-TW': '粤语 (输出粤语繁体)',
  'yue-Hant-HK': '粤语 (输出粤语简体)',
  //'zh-CHT': '中文 + 台语 (输出繁体)',
  'en-US': '英语 (美国)',
  'en-GB': '英语 (英国)',
  'en-AU': '英语 (澳大利亚)',
  'es-ES': '西班牙文 (西班牙)',
  'fr-FR': '法语 (法国)',
  'de-DE': '德文 (德国)',
  'it-IT': '意大利语 (意大利)',
  'pt-PT_pt-BR': '葡萄牙语 (葡萄牙，巴西)',
  'nl-BE': '荷兰语 (比利时)',
  'hi-IN': '印地语 (印度)',
  'ja-JP': '日语 (日本)',
  'ko-KR': '韩文 (韩国)',
  'vi-VN': '越语 (越南)',

  'ar-EG': '阿拉伯语 (埃及)',
  'hy-AM': '亚美尼亚语 (亚美尼亚)',
  'bs-BA': '波斯尼亞語 (波黑)',
  'bg-BG': '保加利亚语 (保加利亚)',
  'my-MM': '缅甸语 (缅甸)',
  'ca-ES': '加泰罗尼亚语 (西班牙)',
  'hr-HR': '克罗地亚语 (克罗地亚)',
  'cs-CZ': '捷克文 (捷克共和国)',
  'da-DK': '丹麦文 (丹麦)',
  //'en-IN': '英语 (印度)',
  //'fil-PH': '菲律宾语 (菲律宾)',
  'tl-PH': '菲律宾语 (菲律宾)',
  'fi-FI': '芬兰文 (芬兰)',
  'gl-ES': '加利西亚语 (西班牙)',
  'el-GR': '希腊文 (希腊)',
  'gu-IN': '古吉拉特语 (印度)',
  'iw-IL': '希伯来语 (以色列)',
  'hu-HU': '匈牙利文 (匈牙利)',
  'id-ID': '印尼文 (印尼)',
  'lv-LV': '拉脱维亚 (拉脱维亚)',
  'lt-LT': '立陶宛语 (立陶宛)',
  'mk-MK': '马其顿语 (北马其顿)',
  'ms-MY': '马来西亚 (马来西亚)',
  'no-NO': '挪威语 (挪威)',
  'pl-PL': '波兰文 (波兰)',
  'ro-RO': '罗马尼亚语 (罗马尼亚)',
  'ru-RU': '俄语 (俄罗斯)',
  'sk-SK': '斯洛伐克 (斯洛伐克)',
  'sl-SI': '斯洛文尼亚语 (斯洛文尼亚)',
  'sv-SE': '瑞典语 (瑞典)',
  'ta-IN': '泰米尔语 (印度)',
  'te-IN': '泰卢固语 (印度)',
  'th-TH': '泰语 (泰国)',
  'tr-TR': '土耳其语 (土耳其)',
  'uk-UA': '乌克兰语 (乌克兰语)',
  'uz-UZ': '乌兹别克 (乌兹别克斯坦)',
  // 'zu-ZA': '祖鲁 (南非)'
}
