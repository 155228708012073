module.exports = {
    convert_to_text_title_meta:"Online AI Video Summarizer: Add Topics, Summarize Meetings, Create YouTube Chapters with One Click",
    convert_to_text_desc_meta:"Our AI analysis tool allows you to efficiently summarize content, pinpoint key speakers, craft narration scripts, and integrate YouTube chapters and timestamps. Explore our range of templates, including video summarizers, YouTube timestamp generators, and video topic timestamp generators. Start with a free trial today!",
    convert_to_text_title:"AI Analysis",
    convert_to_text_desc:"Video summarizer, Youtube Chapters, Meeting Overview",
    start_your_trial:"Start your free trial!",
    hero_img:"./11_English.webp",

    three_step_title:"轉文字三步驟",
    step_one:"Ask a question or give a command",
    step_one_img:"./aianalysis_step1_en_US.webp",
    step_one_desc:"You can choose whether you want the AI video tool to summarize your content, identify key speakers, suggest narration scripts, and add YouTube Chapters and timestamps.",
    
    step_two:"Choose from predefined commands",
    step_two_img:"./aianalysis_step2_en_US.webp",
    step_two_desc:"AI Analysis offers predefined templates like a video summarizer generator, YouTube timestamp generator, and video timestamp topic generator.",

    step_three:"Summarize in your preferred language",
    step_three_img:"./aianalysis_step3_en_US.webp",
    step_three_desc:"Regardless of the video's original language, you can summarize or give commands in your preferred language.",

    feature_tutorial_title:'Video analysis and summarization with Taption',
    feature_tutorial_desc:"The \"AI Analysis\" feature is a powerful tool designed for in-depth video content analysis. Users can upload videos for comprehensive summarization or use predefined templates such as video summary, AI topic generator, YouTube timestamps, and YouTube chapters for one-click analysis, eliminating the need for manual commands. This greatly enhances understanding and interaction with the video's main points.",
    // feature_tutorial_poster_url:'./convert-to-text-tutorial-poster-zh.webp',
    // feature_tutorial_youtube:'https://www.youtube.com/embed/6HYYEdiifbM?autoplay=1',
    feature_tutorial_poster_url:'./aianalysis_poster_en_US.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/PWOWwbHQCUM?autoplay=1',

    feature_why_image:'./ai_template_demo_en.webp',
    feature_extra_title:"AI Analysis usage and examples",
    feature_extra_desc:"To make use of the full potential of the AI Analysis feature, the key lies in crafting precise prompts. Here's how you can articulate your requests to gain the most meaningful insights：<ul><li><strong>Prompt for Summary</strong>：\"Summarize the video in 300 words.\" This prompt is ideal for quick overviews.</li><li><strong>Prompt for Action Items</strong>：\"Identify action items from the meeting.\" This prompt can help pinpoint tasks and responsibilities.</li><li><strong>Prompt for Coaching Feedback</strong>：\"You are a helpful coach. Provide an analysis of the transcript and offer areas to improve with exact quotes. Include no preamble.\"</li><li><strong>Video script</strong>：Please help me create a script for a roughly 5-10 minute explanatory video for this film, focusing on the plot and the ideas the author wishes to convey.</li></ul>When constructing a prompt, begin with an overall summary, then provide additional detailed statements.",

    feature_what_title:"How to timestamp and summarize your YouTube?",
    feature_what_desc: "<ul>\n  <li>Log in to <a href=\"https://app.taption.com/login\">Taption</a></li>\n  <li>Click on \"Upload\" to upload your MP4 video or paste a YouTube link to transcribe</li>\n  <li>When complete, go into the editing platform and edit or translate as needed</li>\n  <li>Click on the \"AI Analysis\" button at the bottom</li>\n  <li>Click \"Analyze\" for YouTube Chapters and Generate Video Summary</li>\n</ul>"

}