module.exports = {
  enjoy_your_free_trial: 'Your first transcription is on us!',
  dialog_title: 'Add Credits',
  subscription_title: 'Get Subscription',
  pay: 'Pay',
  error_unauthenticate: 'We are unable to authorize your payment method, please try with a different card.',
  error_payment_creation: 'Could not complete your payment, please contact support',
  error_general: 'Could not complete connection with server, please refresh and try again',
  error_timeout: 'Connection timeout, please refresh and try again',

  expired_card:'The card has expired, please use a different credit card.',
  do_not_honor:'The card has been declined, please contact your card issuer for more information.',
  insufficient_funds:'The card has insufficient funds to complete this transaction.',
  invalid_info: 'The card info provided was invalid, please check and retry again.',

  success: 'Success',
  success_description: 'Your account has been credit with <br/> <b class="number-of-minutes">{0} minutes</b>',
  success_description_bonus: 'Bonus minutes: <b class ="extra-minutes"> {1} minutes</b> <br/>Your account has been credit with <br/> <b class="number-of-minutes">{0} minutes</b>',
  success_subscription_description: 'Your premium subscription has been activated',
  success_corp_subscription_description: 'Your bulk subscription has been activated',
  cancel_subscription_confirmation: 'Are you sure you want to cancel subscription?',
  success_subscription_bonus: 'Bonus minutes: <b class ="extra-minutes"> {0} minutes</b>',

  cancel_subscription_desc:' minutes will expire immediately upon cancellation<br>Only first <span style="color:rgb(246, 102, 101)!important">1</span> labels can be used after cancellation',
  cancel_subscription_confirm:'I hereby confirm to give up the minutes stated above',
  reactivate_subscription_confirmation: 'Are you sure you want to reactive subscription?',
  cacnel_subscription_description: 'You will still have Premium access until the end of the period.',
  cacnel_subscription: 'Your Premium Subscription has been canceled',
  cancel_corp_subscription: 'Your Bulk Subscription has been canceled',
  reactive_subscription: 'Your subscription has been reactivated!',
  active_until:'Active until',
  success_translation_description: 'Your translation is ready!',
  yes: 'Yes',
  no: 'No',
  continue: 'Continue',
  save: 'Save',
  cancel: 'Cancel',
  processing_payment: 'Processing payment',
  error_edit: 'Could not complete card update, please contact support',
  delete_ok: 'Remove',
  delete_cancel: 'Cancel',
  billing_address: 'Billing Address',
  how_many_hours: 'How many hours?',
  credit_card: 'Credit card',
  summary: 'summary (USD)',
  save_payment: 'Save my card for future purchases',
  processing_payment_description: 'This may take a few minutes...',
  credit_card_manage:'Your credit card is processed securely by Stripe, Inc',
  credit_card_secured:'Transactions are secure and encrypted.',
  understand_billing:'I authorize this payment',
  processing_saving:'Updating credit card',
  // field
  full_name: 'Full name',
  name: 'Name',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  // validation
  max_length_field: '{0} must be at most {1} characters long',
  required_field: '{0} is required',
  delete_title: 'Remove this card?',

  title_basic: 'Standard',
  title_pay_as_you_go: 'Pay-as-you-go',
  title_premium: 'Premium',
  title_custom: 'Custom',
  title_corp: 'Bulk',
  title_subscription:'Subscription',

  basic_feature5: 'Credit <span style="color: #00d1b2;">60 minutes</span>',
  //basic_feature1: 'Automated transcription',
  //basic_feature2: '39+ languages supported',
  //basic_feature3: 'Highlighted text editor',
  //basic_feature4: 'Translate into 50+ languages',
  // basic_feature7: 'Personal AI Dictionary',
  // basic_feature6: 'Allow for commercial use',
  // basic_feature4_tooltip: '',
  //basic_feature4: 'SEO-friendly media player',

  premium_feature1: '{0} <span style="color: #00d1b2;">minutes</span> every month.<br><span class="price-notes">(Carryover minutes, valid until subscription canceled)</span>',
  //premium_feature1_tooltip: 'If the monthly minutes are not consumed, they will be carried over to the next month and will be valid until the subscription is canceled. <br>These monthly minutes are used before the additional minutes purchased separately. <br>The additional minutes purchased separately do not expire after the subscription is canceled.',
  premium_feature2: '25% discount on hourly purchases',
  // premium_feature3: 'Unlimited burned-in subtitles exports for free',
  premium_feature3_tooltip: '$2 per document',
  // premium_feature4: 'Editable transcript sharing',
  // premium_feature5: 'Dark Mode',
  // premium_feature6: 'Edit video by editing text',

  buy: 'Buy minutes',
  activate: 'Activate',
  subscribe: 'Subscribe',
  contact_us: 'Contact us',

  custom_feature1: 'Business partnership',
  custom_feature2: 'Group subscriptions',
  custom_feature3: 'Centralized billing',
  custom_feature4: 'Feedbacks or bugs reporting',

  month: 'month',
  per_hour: 'per hour',
  per_month: 'per month',

  how_get_free:'How can I get free minutes?',
  payment_questions:'Have billing questions?',

  translate_remaining:'You have {0} remaining.',
  translate_deduct:'We will deduct {0} if you translate this transcript.',
  transcribe_deduct:'We will deduct {0} if you transcribe this transcript.',
  transcribe_warning:'Please ensure you have enough minutes to transcribe, otherwise the process will fail.',
  mp4_deduct:'We will deduct {0} if you export this transcript as MP4.',
  cvc:'What is CVC?',
  confirm_translate:'I want to proceed with the translation',
  recurring_description:'＊ Recurring monthly payment',
  recurring_description2:'＊ Cancel anytime under「Account」',
  coupon_header:'Promotion Code',
  coupon_title:'Enter your promotion code:',
  coupon_submit:'Apply',
  processing_coupon: 'Applying coupon code',
  coupon_empty: 'Please enter your coupon code',
  coupon_expire: 'The coupon has expired',
  coupon_notexist: 'The coupon is not valid',
  coupon_firsttimeonly: 'The coupon is only valid for new user',
  member_slot_desc:'Total member slots for this subscription to invite members with different permissions to <br>add, upload, edit and or export transcriptions from this account.',
  credit_edit_title:'Credit card information',

  payment_confirm_title:'Payment confirmation',
  payment_confirm_desc:'This payment is charged in <span style="color:#2fc59f;font-weight:bold;">U.S. dollars</span> with the amount of {0}.',
  upgrade_contact_us:'For enterprise solutions, affiliate programs, or business inquiries, please contact us at <span style="color:#2fc59f">help@taption.com</span>'
}
