module.exports = {
    title_0: '如何把影片转成文字?',
    desc_0:'请参考教学影片',
    video_0:"https://www.youtube.com/embed/4eWCXvkSiew",

    title_1: '如何为影片加字幕?',
    desc_1:'请参考教学影片',
    video_1:"https://www.youtube.com/embed/509iVOUAxcY",
    
    title_2: '如何翻译影片?',
    desc_2:'请参考教学影片',
    video_2:"https://www.youtube.com/embed/q3YjCHoU8hU",
    
    title_3: '如何使用『AI辞典』和『全部替换』来增加精确性?',
    desc_3:'请参考教学影片',
    video_3:"https://www.youtube.com/embed/wRx_OfdDMJY",
    
    title_4: '如何使用『字幕剪辑影片片段』功能?',
    desc_4:'请参考教学影片',
    video_4:"https://www.youtube.com/embed/W_p0MlPw8hk",

    title_5: '如何下载发票和收据?',
    desc_5:'订阅后，可随时到 <span style="color:rgb(0, 209, 178);">我的帐号 → 订阅发票 → 查看</span> 下载',
    video_5:"",
    
    title_6: '订阅后，每个月赠送的分钟数之外我还需要更多的分钟数，可以怎么办?',
    desc_6:'如订阅<span style="color:rgb(0, 209, 178);">高级订阅</span>，可以优惠价格<span style="color:rgb(0, 209, 178);">6美金/小时</span>加购分钟数。如订阅<span style="color:rgb(0, 209, 178);">大量订阅</span>，可以优惠价格<span style="color:rgb(0, 209, 178);">3美金/小时</span>加购分钟数。 ',
    video_6:"",

    title_7: '翻译后还是原来的语言，要怎么解决?',
    desc_7:'请确认一开始上传选择的语言跟影片里的发声语言一样。 (例: 上传英文影片时，语言请选择『英文』。完成后再进入编辑平台右上方进行翻译)',
    video_7:"",

    title_8: '上传的档案小于5GB，为什么得到 <span style="color:rgb(230, 118, 117);">影片过长</span> 的讯息?',
    desc_8:'5GB是以MP4的档案为准，如果上传其它类型(例:MOV)，伺服器会先转档成MP4。如果转完的档案大于5GB则会出现此讯息。可以自行转成MP4再上传来避免此讯息。',
    video_8:"",

    title_9: '这些错误讯息要如何解决?',
    desc_9:'<span style="color:rgb(230, 118, 117);">试用超额</span> : 请订阅或购买更多分钟数<br><span style="color:rgb(230, 118, 117);">您的分钟数不足</span> : 请订阅或购买更多分钟数<br><span style="color:rgb(230, 118, 117);">此Youtube连结失败，请再试一次</span> : 通常会发生在Youtube新上传的影片上，请等待至少24小时后再重新上传.',
    video_9:"",

    title_10: '如何将Taption与Zoom做串联?',
    desc_10:'请参考<a href="https://www.taption.com/blog/zh/zoom-tutorial-zh" rel="noreferrer" target="_blank">教学文章</a>',
    video_10:"",

    title_11: '我有更多的问题，要怎么联络你们?',
    desc_11: '请寄信到我们的信箱: <span style="color:rgb(0, 209, 178);">help@taption.com</span>',
    video_11:"",


    zoom_title:'如何将Taption与Zoom做串联?',
    zoom_install_desc:'您可以将您的Zoom帐号与Taption做串联，让您可以直接从Taption介面选取需要转成文字的Zoom会议。 ',
    zoom_step_1:'登入后点选「上传」',
    zoom_step_2:'选择Zoom作为影片来源',
    zoom_step_3:'进入Zoom页面后登入帐号并认证',
    zoom_step_4:'选择会议影片',
    zoom_step_5:'设定完后就可以上传转成文字了',
    zoom_uninstall_title:'如何取消Taption和Zoom的串联?',
    zoom_uninstall_desc:'要取消串联或是更换Zoom帐号可以到 <strong>我的帐号</strong> → <strong>已连接软体</strong> → <strong>取消串联</strong><br>如有更多问题欢迎寄信到 <span style="color:rgb(0, 209, 178);">help@taption.com</span>',

    webex_title:'如何将Taption与Webex做串联?',
    webex_install_desc:'您可以将您的Webex帐号与Taption做串联，让您可以直接从Taption介面选取需要转成文字的Webex会议。 ',
    webex_step_1:'登入后点选「上传」',
    webex_step_2:'选择Webex作为影片来源',
    webex_step_3:'进入Webex页面后登入帐号并认证',
    webex_step_4:'选择会议影片',
    webex_step_5:'设定完后就可以上传转成文字了',
    webex_uninstall_title:'如何取消Taption和Webex的串联?',
    webex_uninstall_desc:'要取消串联或是更换Webex帐号可以到 <strong>我的帐号</strong> → <strong>已连接软体</strong> → <strong>取消串联</strong><br>如有更多问题欢迎寄信到 <span style="color:rgb(0, 209, 178);">help@taption.com</span>'

}