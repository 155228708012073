module.exports = {
    convert_to_text_title:"動画上の話し手ラベル付け",
    convert_to_text_desc:"あなたの動画や音声ファイル上の話し手を自動的にラベル付けます。",
    start_your_trial:"無料で試す",
    hero_img:"./04_English.webp",

    step_one:"ファイルをアップロードします",
    step_one_img:"./select_source_en.webp",
    step_one_desc:"デバイス上のファイルまたはYouTube、Google Drive、Zoomなどからファイルをアップロードします。",
    
    step_two:"話し手ラベル付けの設定",
    step_two_img:"./speaker_step2_en.webp",
    step_two_desc:"アップロード時に動画と一致する言語を選択し、各\"speaker\"のセクションにマッチするカテゴリーを選びます",

    step_three:"完了",
    step_three_img:"./speaker_step3_en.webp",
    step_three_desc:"字幕挿入が完了した後、編集プラットフォームでラベルの調整を行えます。",

    feature_tutorial_title:'話し手の自動ラベル付けに関するチュートリアル',
    feature_tutorial_desc:'自動ラベル付けで、動画上の話し手を素早く識別できます。下のチュートリアルを今すぐチェック！',
    feature_tutorial_poster_url:'./speaker_labeling_tutorial_poster_en.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/l5xkhJZy5ww?autoplay=1',

    feature_extra_title:"打合せやビデオ会議の文字起こしのメリット",
    feature_extra_desc:"打ち合わせやビデオ会議を文字起こしすると、色んなメリットがあります。<ul><li>文字起こしによって情報の保存が容易になります</li><li>打ち合わせで使われたキーワードをSEO改善に活用できます</li><li>文字起こしでコンテンツへのアクセスを増やせます</li><li>貴社ビジネスに関する社内の理解が向上し、ROI改善につなげます</li></ul>"
}