module.exports = {
convert_to_text_title_meta: "在线AI语音转文字：自动将视频、录音、YouTube转为逐字稿",
convert_to_text_desc_meta: "在线自动将视频、录音、YouTube音频转换为文字，支持40多种语言并提供高准确率。功能包括逐字稿输出、会议记录人物分段，以及便于添加字幕的分段处理。免费体验，立即注册开始使用！",
convert_to_text_title:"语音转文字",
 convert_to_text_desc:"自动将MP4影片，MP3录音转为嵌入时间码的逐字稿，支援超过40种语言，自动分段，高准确率",
 start_your_trial:"马上试！",
 hero_img:"./01_Chinese.webp",

 three_step_title:"语音转文字三步骤",
 step_one:"选择上传影音档",
 step_one_img:"./select_source_zh.webp",
 step_one_desc:"从电脑、Youtube、Google Drive、Zoom或影音档下载连结选择影片或录音后上传",

 step_two:"选择转成文字方式",
 step_two_img:"./select_split_zh.webp",
 step_two_desc:"从清单选取: 1) AI自动生成 2) 汇入纯文字档 3)手动输入。分段方式选择：以「逐字稿」形式分段。 ",

 step_three:"导出",
 step_three_img:"./select_export_zh.webp",
 step_three_desc:"进入编辑平台进行校正。完后即可从右上角以各种形式导出(srt,txt,pdf,mp4)",

 feature_tutorial_title:'如何将语音转文字？ ',
 feature_tutorial_desc:'无论您需要将MP4或MP3文件进行语音转文字，我们的编辑平台都可以协助您进行逐字稿的调整、段落编排和时间对齐，最终可以导出调整后的文件。 <br>Taption提供多种语音转文字方式包括：<span class="bullet-point">•</span>AI自动上字幕<span class="bullet-point">•</span>导入已有的SRT档<span class="bullet-point">•</span>汇入TXT纯文字档<span class="bullet-point">•</span>手动输入。 <br>我们的强大编辑平台将大幅缩短校对时间，同时Taption支持超过50种语言的双语字幕制作。欢迎观看我们的教学影片，了解如何有效使用语音转文字功能！点开看我们的教学影片：',
 // feature_tutorial_poster_url:'./convert-to-text-tutorial-poster-zh.webp',
 // feature_tutorial_youtube:'https://www.youtube.com/embed/6HYYEdiifbM?autoplay=1',
 feature_tutorial_poster_url:'./add_subtitles_tutorial_poster_zh.webp',
 feature_tutorial_youtube:'https://www.youtube.com/embed/6HYYEdiifbM?autoplay=1',

 feature_extra_title:"语音转文字的用途",
 feature_extra_desc:"语音转文字不仅用于上字幕，还适用于多种场合：<ul><li>方便企划或剪辑师迅速定位特定内容或字词在哪部影片的哪一段落出现，语音转文字功能便于引用之前的影片内容，实现主题的呼应。</li><li>修改和审阅影片内容时，语音转文字方便像是撰写影片的文案，对即兴创作者在事后优化内容特别有帮助。</ li><li>将影片翻译成文字稿和撷取重点翻译变得特别方便，语音转文字提升多语种内容的创作效率。</li><li>将影片内容转成逐字稿后，进行修改和润饰成文章发布，语音转文字有助于提高SEO，特别适合同时经营Vlog和Blog的创作者。</li><li>语音转文字功能使听力障碍者也能享受影片带来的信息和价值。</li></ul><br>通过语音转文字功能，我们不仅提高了内容的可及性，也增强了影片的多用途性和实用价值。",

 feature_why_image: "./translate_step1_zh.webp",
 feature_what_title: "语音转成文字5步骤",
 feature_what_desc: "<ul>\n  <li>注册新账号或使用 Gmail 登录：前往 <a href=\"https://app.taption.com/login\">Taption 登录页面</a>。</li>\n  <li>点击“上传”按钮，选择影音来源，如从电脑上传、YouTube、Google Drive、Zoom 或直接粘贴影音文件链接。</li>\n  <li>选择转写所需的“语言”及“分段方式”。</li>\n  <li>完成上传后，文件会自动转成文字并可进入编辑平台进行编辑。</li>\n  <li>在编辑平台，您可以进行翻译、AI 分析，最后将文件或字幕导出并嵌入至视频文件中。</li>\n</ul>"
}