module.exports = {
    convert_to_text_title:"用户管理，权限分配",
    convert_to_text_desc:"一个帐号加入多个用户，共享分钟和共同编辑来提高效率！",
    start_your_trial:"马上试！",
    hero_img:"./06_Chinese.webp",

    step_one:"一个帐号多个用户",
    step_one_img:"./collaborate_step1_zh.webp",
    step_one_desc:"加入多个用户共同使用帐号，",
    
    step_two:"分钟，影片共享管理",
    step_two_img:"./collaborate_step2_zh.webp",
    step_two_desc:"管理用户的分钟使用，也能让多个用户同时编辑",

    step_three:"6种用户权限",
    step_three_img:"./collaborate_step3_zh.webp",
    step_three_desc:"可以给用户6种不同的权限",

    feature_tutorial_title:'用户管理教学',
    feature_tutorial_custom_desc:'除了预设的6种权限，也能客制化的去针对每个用户的阅览影片，编辑文字，及相关功能等权限。 ',
    feature_tutorial_custom_url:'./custom_dialog_zh.webp',
    feature_tutorial_desc:'多个用户共享不仅可以节省支出，也能大大的增加效率！快来看看下方影片关于用户管理的介绍吧！ ',
    feature_tutorial_poster_url:'./permission_tutorial_poster_zh.webp',
    feature_tutorial_youtube:'https://www.youtube.com/embed/JTEaovV4k8Q?autoplay=1',

    feature_extra_title:"共享编辑为什么变成了趋势？",
    feature_extra_desc:"<ul><li>网路及远端工作的时代：在网路速度越来越快、远端工作成为主流的现在，共同作业的概念迁移到了网路上，让所有参与专案者都能不受空间、时间限制地作业</li><li>各大软体都有共享编辑功能：I. Google Doc：云端共享编辑，别人修改哪些内容都能一目了然 II. 微软：Office系列的如Word、Powerpoint、Excel等编辑软体，还有Onedrive III. Adobe：Photoshop、illustrator、Premiere Pro……等，从照片、文件到影片都能共同编辑</li><li>利用共享编辑功能的好处：有些人可能觉得可以各自完成作业再整合就好，但每个人工作速度不一样，有些需要配合他人进度的工作，藉由共享编辑的功能，可以促进整体的工作效率，整个专案、作品的轮廓也更加清晰，而且有任何改动都不用再经过繁复的过程</li></ul>"
}